import React from 'react';
import {FilterMenuItem} from './FilterMenuItem';
import {FilterMenuRangeItem} from './FilterMenuRangeItem';

const dropdownStyle = {
    minWidth: '98%'    
}

export class FilterBar extends React.Component {
    render() {
        let menuItemConfig = [
            {
                filter: this.props.filter.city,
                filterItems: this.props.cityFilter,
                scrollable: this.props.cityFilter.length > 11,
                label: "Stad",
                icon: "fas fa-city",
                key: "city",
                filterType: "broker"
            },
            {
                filter: this.props.filter.area,
                filterItems: this.props.areaFilter,
                scrollable: this.props.areaFilter.length > 11,
                label: "Område",
                icon: "fas fa-map-marker-alt",
                key: "area",
                filterType: "broker"
            },
            {
                filter: this.props.filter.brand,
                filterItems: this.props.brandFilter,
                scrollable: this.props.brandFilter.length > 11,
                label: "Märke",
                icon: "fas fa-car-alt",
                key: "brand",
                filterType: "auto"
            },
            {
                filter: this.props.filter.model,
                filterItems: this.props.modelFilter,
                scrollable: this.props.modelFilter.length > 11,
                label: "Modell",
                icon: "fas fa-car-side",
                key: "model",
                filterType: "auto"
            },
            {
                filter: this.props.filter.type,
                filterItems: this.props.typeFilter,
                scrollable: this.props.typeFilter.length > 11,
                label: "Typ",
                icon: "fas fa-plus-square",
                key: "type",
                filterType: "broker auto grocery event"
            },
            {
                filter: this.props.filter.running,
                filterItems: this.props.runningFilter,
                scrollable: false,
                label: "Förhandsvisning",
                icon: "fas fa-sign",
                key: "running",
                filterType: "broker"
            },
            {
                filter: this.props.filter.status,
                filterItems: this.props.statusFilter,
                scrollable: false,
                label: "Status",
                icon: "fas fa-plus-circle",
                key: "status",
                filterType: "auto"
            },
            {
                filter: this.props.filter.fuel,
                filterItems: this.props.fuelFilter,
                scrollable: this.props.fuelFilter.length > 11,
                label: "Drivmedel",
                icon: "fas fa-gas-pump",
                key: "fuel",
                filterType: "auto"
            },
            {
                filter: this.props.filter.affiliate,
                filterItems: this.props.affiliateFilter,
                scrollable: this.props.affiliateFilter.length > 11,
                label: "Filial",
                icon: "fas fa-store",
                key: "affiliate",
                filterType: "broker auto"
            },
            {
                filter: this.props.filter.numObjects,
                filterItems: this.props.numObjectsFilter,
                scrollable: false,
                label: "Antal objekt per produkttyp",
                icon: "fas fa-list-ol",
                key: "numObjects",
                filterType: "grocery"
            },
            {
                filter: this.props.filter.offerType,
                filterItems: this.props.offerTypeFilter,
                scrollable: false,
                label: "Erbjudandetyp",
                icon: "fas fa-shopping-basket",
                key: "offerType",
                filterType: "grocery"
            },
            {
                filter: this.props.filter.status,
                filterItems: this.props.eventStatusFilter,
                scrollable: false,
                label: "Status",
                icon: "fas fa-ticket-alt",
                key: "status",
                filterType: "event"
            },
        ]
        let menuItems = menuItemConfig.map((item) => {
            if (item.filterType.includes(this.props.customerType) && item.filterItems.length > 1) {
                return <FilterMenuItem
                            key={item.key}
                            filter={item.filter} 
                            filterItems={item.filterItems}
                            scrollable={item.scrollable}
                            label={item.label}
                            icon={item.icon}
                        />
            }
            else {
                return null;
            }
        });

        let menuRangeItemConfig = [
            {
                filter: this.props.filter.price,
                filterFrom: this.props.priceFromFilter,
                filterTo: this.props.priceToFilter,
                label: "Pris",
                icon: "fas fa-dollar-sign",
                key: "price",
                filterType: "auto"
            },
            {
                filter: this.props.filter.year,
                filterFrom: this.props.yearFromFilter,
                filterTo: this.props.yearToFilter,
                label: "Årsmodell",
                icon: "fas fa-calendar-alt",
                key: "year",
                filterType: "auto"
            },
            {
                filter: this.props.filter.mileage,
                filterFrom: this.props.mileageFromFilter,
                filterTo: this.props.mileageToFilter,
                label: "Miltal",
                icon: "fas fa-tachometer-alt",
                key: "mileage",
                filterType: "auto"
            }
        ]
        let menuRangeItems = menuRangeItemConfig.map((item) => {
            if (item.filterType.includes(this.props.customerType) && item.filterFrom.length > 1) {
                return <FilterMenuRangeItem 
                            key={item.key}
                            filter={item.filter} 
                            filterFrom={item.filterFrom}
                            filterTo={item.filterTo}
                            label={item.label}
                            icon={item.icon}
                        />
            }
            else {
                return null;
            }
        });

        return (
            <div className="row shadow bg-white sticky-top filterbar border-bottom animated fadeInDown delay-1s">
                <ul className="nav w-100">
                    <li className="nav-item p-1 col-sm-12 col-md-12 col-lg-3 dropdown filter-dropdown dropdown-keep-open">
                        <button className="btn btn-primary text-white w-100 text-left nav-link dropdown-toggle" id="filter-dropdown-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-filter mr-2"></i>
                            <span>Filtrera och sortera </span>
                            <span className="ml-1 mr-1 mt-1 badge badge-light bg-white text-primary float-right">{this.props.numFilters > 0 ? this.props.numFilters : ''}</span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="filter-dropdown-button" style={dropdownStyle}>
                            {menuItems}
                            {menuRangeItems}

                            {this.props.customerType === "event" && this.props.eventDateFilter}

                            <div className="dropdown-divider"></div>
                            <FilterMenuItem
                                key={"sorting"}
                                filter={this.props.filter.sorting} 
                                filterItems={this.props.sortingOptions}
                                scrollable={false}
                                label={"Sortera"}
                                icon={"fas fa-sort-amount-down"}
                            />
                        </ul>
                    </li>
                    <li className="nav-item p-1 col-sm-12 col-md-6 col-lg dropdown">
                        <input type="search" className="form-control search-input" data-filtertype="text-filter" placeholder="Sök" aria-label="text-filter" onChange={(e) => this.props.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}></input>
                    </li>
                    <li className="nav-item p-1 col-sm-12 col-md-6 col-lg-3 dropdown">
                        <button className="btn btn-danger text-white w-100 text-left nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="far fa-trash-alt mr-2"></i>
                            <span>Rensa </span>
                        </button>
                        <ul className="dropdown-menu pt-2 shadow" style={dropdownStyle}>
                            <li className="dropdown-item p-0" key="reset-filterbar">
                                <button className="dropdown-item" data-reset="filterbar" onClick={this.props.resetFilter}>
                                    Rensa filterbar
                                </button>
                            </li>
                            <li className="dropdown-item p-0" key="reset-hidden">
                                <button className="dropdown-item" data-reset="hidden" onClick={this.props.resetFilter}>
                                    Rensa dolda objekt
                                </button>
                            </li>
                            <li className="dropdown-item p-0" key="reset-starred">
                                <button className="dropdown-item" data-reset="starred" onClick={this.props.resetFilter}>
                                    Rensa stjärnmarkering
                                </button>
                            </li>
                            <li className="dropdown-item p-0" key="reset-all">
                                <button className="dropdown-item" data-reset="all" onClick={this.props.resetFilter}>
                                    Rensa alla filter
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}