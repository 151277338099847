import React from 'react';
import {Navbar} from '../components/Navbar';
import $ from 'jquery';

export class NavbarContainer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            filteredCustomers: this.props.customers,
            textFilter: null,
            typeFilter: null
        }

        this.chooseCustomer = this.chooseCustomer.bind(this);
        this.setView = this.setView.bind(this);
        this.textFilterChange = this.textFilterChange.bind(this);
        this.filterCustomers = this.filterCustomers.bind(this);
        this.typeFilterChange = this.typeFilterChange.bind(this);
    }

    getSnapshotBeforeUpdate(prevProps, prevState){
        if (prevProps.customers !== this.props.customers){
            return this.props.customers;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (snapshot !== null) {
            this.setState({filteredCustomers: snapshot})
        }

        if (this.state.textFilter !== prevState.textFilter || this.state.typeFilter !== prevState.typeFilter) {
            this.setState({filteredCustomers: this.filterCustomers()});
        }

        $('.dropdown-keep-open').on('hide.bs.dropdown', ev => !(ev.clickEvent && $(ev.target).has(ev.clickEvent.target).length))
    }

    filterCustomers() {
        let filteredCustomers = [];
        for (let i = 0; i < this.props.customers.length; i++) {
            const customer = this.props.customers[i];
            let customerIsGood = true;
            if (this.state.typeFilter !== null && this.state.typeFilter !== "") {
                customerIsGood = (customer.type.includes(this.state.typeFilter) && customerIsGood) ? true : false;
            }
            if (this.state.textFilter !== null && this.state.textFilter !== "") {
                customerIsGood = ((customer.name.toLowerCase().includes(this.state.textFilter) || customer.customerId.toLowerCase().includes(this.state.textFilter) || customer.office.toLowerCase().includes(this.state.textFilter)) && customerIsGood) ? true : false;
            }
            if (customerIsGood) {
                filteredCustomers.push(customer);
            }
        }
        return filteredCustomers;
    }

    chooseCustomer(e){
        $('.dropdown.show .dropdown-toggle').dropdown('toggle');
        let newCustomerId = e.currentTarget.dataset.customerid;
        this.props.updateCustomer(newCustomerId);
    }

    textFilterChange(e){
        let filter = e.currentTarget.value.toLowerCase();
        this.setState({textFilter: filter});
    }

    typeFilterChange(e) {
        let type = e.currentTarget.dataset.customertype;
        this.setState({typeFilter: type});
    }

    setView(e){
        const subView = e.currentTarget.dataset.admin;
        this.props.setCurrentView('admin', subView);
    }

    render(){
        const customers = this.state.filteredCustomers;
        let customerList = customers.map((customer)=>{
            const icons = {
                'broker': <i className="fas fa-home"></i>,
                'auto': <i className="fas fa-car"></i>,
                'grocery': <i className="fas fa-shopping-cart"></i>,
                'news': <i className="fas fa-bullhorn"></i>,
                'event': <i className="fas fa-calendar-check"></i>
            }
            return (
                <tr 
                    href="#" 
                    key={customer.customerId} 
                    data-customerid={customer.customerId} 
                    onClick={this.chooseCustomer}
                    style={{cursor: 'pointer'}}
                >
                    <td className="align-middle pr-0">&nbsp;{icons[customer.type]}</td>
                    <td className="align-middle">{customer.name}</td>
                    <td className="align-middle text-center"><span className="badge badge-secondary ml-2 font-weight-light" style={{fontSize: 14}}>{customer.office}</span></td>
                    <td className="text-center font-weight-bold text-muted align-middle">{customer.customerId}</td>
                    <td>
                        <img 
                            src={customer.logoUrl} 
                            style={{maxHeight:30}}
                            alt="kundlogo"
                            className="border rounded p-1 bg-white"></img>
                    </td>
                </tr>
            )
        });

        return (
            <Navbar 
                customers={customerList} 
                customerObject={this.props.customerObject}
                user={this.props.user} 
                loggedIn={this.props.loggedIn} 
                buttonClick={this.props.onClick}
                setView={this.setView}
                currentView={this.props.view}
                getUsers={this.props.getUsers}
                handleFocus={this.handleFocus}
                textFilterChange={this.textFilterChange}
                typeFilterChange={this.typeFilterChange}
            />
        )
    }
}