import React from 'react';
import {FilterBar} from '../components/CampaignList/FilterBar/FilterBar';
import {FilterSubmenuItem} from '../components/CampaignList/FilterBar/FilterSubmenuItem';
import {FilterSubmenuRangeItem} from '../components/CampaignList/FilterBar/FilterSubmenuRangeItem';
import {DateRangePicker} from '../components/CampaignList/FilterBar/DateRangePicker';
import {filtersCheckChange, filtersResetFilter} from '../handlers/filters';
import $ from 'jquery';

export class FilterBarContainer extends React.Component {
    constructor(props) {
        super(props);

        this.onCheckChange = this.onCheckChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }

    componentDidMount() {
        // https://stackoverflow.com/a/51876910
        $('.dropdown-menu button.dropdown-toggle').on('click', function(e) {
            if (!$(this).next().hasClass('show')) {
                $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
            }
            let $subMenu = $(this).next('.dropdown-menu');
            $subMenu.toggleClass('show');    
            
            $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
                $('.dropdown-submenu .show').removeClass('show');
            });
            
            return false;
        });

        // https://stackoverflow.com/a/55435771
        $('.dropdown-keep-open').on('hide.bs.dropdown', ev => !(ev.clickEvent && $(ev.target).has(ev.clickEvent.target).length))
    }
    
    resetFilter(e) {
        let filters = filtersResetFilter(e, this.props.filter);
        this.props.updateFilters(filters);
    }

    onCheckChange(value, filtertype) {
        let filters = filtersCheckChange(value, filtertype, this.props.filter);
        this.props.updateFilters(filters)
    }

    render() {
        let defaultObj = JSON.parse(JSON.stringify(this.props.defaultObjects));

        let cityArr = [];
        let areaArr = [];
        let typeArr = [];
        let brandArr = [];
        let modelArr = [];
        let fuelArr = [];
        let affiliateArr = [];
        let yearArr = [];
        let carsPriceArr = [];
        let carsMileageArr = [];

        defaultObj.forEach(obj => {
            if (!cityArr.includes(obj.details.city) && obj.details.city) {
                cityArr.push(obj.details.city);
            }
            if (!areaArr.includes(obj.details.area) && obj.details.area) {
                areaArr.push(obj.details.area);
            }
            if (!typeArr.includes(obj.details.type) && obj.details.type) {
                typeArr.push(obj.details.type);
            }
            if (!brandArr.includes(obj.details.brand) && obj.details.brand) {
                brandArr.push(obj.details.brand);
            }
            if (!modelArr.includes(obj.details.model) && obj.details.model) {
                modelArr.push(obj.details.model);
            }
            if (!fuelArr.includes(obj.details.fuel) && obj.details.fuel) {
                fuelArr.push(obj.details.fuel);
            }
            if (!affiliateArr.includes(obj.details.affiliate) && obj.details.affiliate) {
                affiliateArr.push(obj.details.affiliate);
            }
            if (!yearArr.includes(obj.details.year) && obj.details.year) {
                yearArr.push(obj.details.year);
            }
            if (!carsPriceArr.includes(obj.details.price) && obj.details.price) {
                carsPriceArr.push(obj.details.price);
            }
            if (!carsMileageArr.includes(obj.details.mileage) && obj.details.mileage) {
                carsMileageArr.push(obj.details.mileage);
            }
        });

        carsPriceArr.sort((a, b) => a - b);
        let priceArr = [];
        let p = 0;
        priceArr.push(p);
        do {
            p += 50000;
            priceArr.push(p);
        } while (p < carsPriceArr[carsPriceArr.length-1]);
        
        carsMileageArr.sort((a, b) => a - b);
        let mileageArr = [];
        let m = 0;
        mileageArr.push(m);
        do {
            m += 2500;
            mileageArr.push(m);
        } while (m < carsMileageArr[carsMileageArr.length-1]);

        cityArr.sort();
        areaArr.sort();
        typeArr.sort();
        brandArr.sort();
        modelArr.sort();
        fuelArr.sort();
        affiliateArr.sort();
        yearArr.sort();

        /** 
         * === === === === === === === === === === === ===
         * FILTER FÖR AURA BOSTAD
         * === === === === === === === === === === === ===
         */
        let cityFilter = cityArr.map((city, i) => {
            return <FilterSubmenuItem key={"city-filter-" + i} filterType={"city"} filterItem={city} filter={this.props.filter.city} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} i={i} />;
        });
        let areaFilter = areaArr.map((area, i) => {
            return <FilterSubmenuItem key={"area-filter-" + i} filterType={"area"} filterItem={area} filter={this.props.filter.area} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} i={i} />;
        });
        let typeFilter = typeArr.map((type, i) => {
            return <FilterSubmenuItem key={"type-filter-" + i} filterType={"type"} filterItem={type} filter={this.props.filter.type} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} i={i} />;
        });
        let runningFilter = (
            [
                <li className="mb-1 dropdown-item pl-2" key="running-headStart">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="running" name="runningRadioButton" id="running-headStart" value="headStart" checked={this.props.filter.running.includes("headStart")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="running-headStart">Endast förhandsvisning</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="running-normal">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="running" name="runningRadioButton" id="running-normal" value="normal" checked={this.props.filter.running.includes("normal")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="running-normal">Ej förhandsvisning</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="running-all">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="running" name="runningRadioButton" id="running-all" value="all" checked={this.props.filter.running.length <= 0} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="running-all">Visa alla</label>
                    </div>
                </li>
            ]
        );

        /** 
         * === === === === === === === === === === === ===
         * FILTER FÖR AURA MOTOR
         * === === === === === === === === === === === ===
         */
        let brandFilter = brandArr.map((brand, i) => {
            return <FilterSubmenuItem key={"brand-filter-" + i} filterType={"brand"} filterItem={brand} filter={this.props.filter.brand} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} i={i} />;
        });
        let modelFilter = modelArr.map((model, i) => {
            return <FilterSubmenuItem key={"model-filter-" + i} filterType={"model"} filterItem={model} filter={this.props.filter.model} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} i={i} />;
        });
        let fuelFilter = fuelArr.map((fuel, i) => {
            return <FilterSubmenuItem key={"fuel-filter-" + i} filterType={"fuel"} filterItem={fuel} filter={this.props.filter.fuel} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} i={i} />;
        });
        let affiliateFilter = affiliateArr.map((affiliate, i) => {
            return <FilterSubmenuItem key={"affiliate-filter-" + i} filterType={"affiliate"} filterItem={affiliate} filter={this.props.filter.affiliate} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} i={i} />;
        });
        let priceFromFilter = priceArr.map((price, i) => {
            return <FilterSubmenuRangeItem key={"price-from-filter-" + i} filter={this.props.filter.price.from} filterType={"price-from"} filterItem={price} label={price.toLocaleString('se') + " kr"} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} disabled={this.props.filter.price.to !== null && this.props.filter.price.to < price} i={i} />;
        });
        let priceToFilter = priceArr.map((price, i) => {
            return <FilterSubmenuRangeItem key={"price-to-filter-" + i} filter={this.props.filter.price.to} filterType={"price-to"} filterItem={price} label={price.toLocaleString('se') + " kr"} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} disabled={this.props.filter.price.from !== null && this.props.filter.price.from > price} i={i} />;
        });
        let yearFromFilter = yearArr.map((year, i) => {
            return <FilterSubmenuRangeItem key={"year-from-filter-" + i} filter={this.props.filter.year.from} filterType={"year-from"} filterItem={year} label={year} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} disabled={this.props.filter.year.to !== null && this.props.filter.year.to < year} i={i} />;
        });
        let yearToFilter = yearArr.map((year, i) => {
            return <FilterSubmenuRangeItem key={"year-to-filter-" + i} filter={this.props.filter.year.to} filterType={"year-to"} filterItem={year} label={year} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} disabled={this.props.filter.year.from !== null && this.props.filter.year.from > year} i={i} />;
        });
        let mileageFromFilter = mileageArr.map((mileage, i) => {
            return <FilterSubmenuRangeItem key={"mileage-from-filter-" + i} filter={this.props.filter.mileage.from} filterType={"mileage-from"} filterItem={mileage} label={mileage.toLocaleString('se') + " mil"} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} disabled={this.props.filter.mileage.to !== null && this.props.filter.mileage.to < mileage} i={i} />;
        });
        let mileageToFilter = mileageArr.map((mileage, i) => {
            return <FilterSubmenuRangeItem key={"mileage-to-filter-" + i} filter={this.props.filter.mileage.to} filterType={"mileage-to"} filterItem={mileage} label={mileage.toLocaleString('se') + " mil"} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} disabled={this.props.filter.mileage.from !== null && this.props.filter.mileage.from > mileage} i={i} />;
        });
        let statusFilter = (
            [
                <li className="mb-1 dropdown-item pl-2" key="status-used">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="status" name="statusRadioButton" id="status-used" value="used" checked={this.props.filter.status.includes("used")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="status-used">Begagnade bilar</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="status-new">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="status" name="statusRadioButton" id="status-new" value="new" checked={this.props.filter.status.includes("new")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="status-new">Nya bilar</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="status-all">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="status" name="statusRadioButton" id="status-all" value="all" checked={this.props.filter.status.length <= 0} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="status-all">Visa alla</label>
                    </div>
                </li>
            ]
        );

        /** 
         * === === === === === === === === === === === ===
         * FILTER FÖR AURA LIVS
         * === === === === === === === === === === === ===
         */
        const numObjArr = [1, 2, 3, 4, 5];
        let numObjectsFilter = numObjArr.map((numObjects, i) => {
            return <FilterSubmenuRangeItem key={"numObjects-filter-" + i} filter={this.props.filter.numObjects} filterType={"numObjects"} filterItem={numObjects} label={numObjects + " objekt"} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} disabled={false} i={i} />;
        });
        let offerTypeFilter = (
            [
                <li className="mb-1 dropdown-item pl-2" key="offerType-filter-1">
                    <div className="custom-control custom-checkbox">
                        <input 
                            type="checkbox" className="custom-control-input filter-checkbox" 
                            data-filtertype="offerType"
                            id="offerType-filter-1"
                            value="special" 
                            onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} 
                            checked={this.props.filter.offerType.includes("special")} 
                        />
                        <label 
                            className="custom-control-label" 
                            htmlFor="offerType-filter-1" 
                        >Veckans erbjudanden</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="offerType-filter-2">
                    <div className="custom-control custom-checkbox">
                        <input 
                            type="checkbox" className="custom-control-input filter-checkbox" 
                            data-filtertype="offerType"
                            id="offerType-filter-2"
                            value="member" 
                            onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)} 
                            checked={this.props.filter.offerType.includes("member")} 
                        />
                        <label 
                            className="custom-control-label" 
                            htmlFor="offerType-filter-2" 
                        >Medlemserbjudanden</label>
                    </div>
                </li>
            ]
        );

        /** 
         * === === === === === === === === === === === ===
         * FILTER FÖR AURA EVENT
         * === === === === === === === === === === === ===
         */
        let eventStatusFilter = (
            [
                <li className="mb-1 dropdown-item pl-2" key="status-available">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="status" name="statusRadioButton" id="status-available" value="available" checked={this.props.filter.status.includes("available")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="status-available">Visa inte slutsålda</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="status-soldOut">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="status" name="statusRadioButton" id="status-soldOut" value="soldOut" checked={this.props.filter.status.includes("soldOut")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="status-soldOut">Visa endast slutsålda</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="status-all">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="status" name="statusRadioButton" id="status-all" value="all" checked={this.props.filter.status.length <= 0} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="status-all">Visa alla</label>
                    </div>
                </li>
            ]
        );
        let eventDateFilter = (
            <li className="dropdown-submenu">
                <button className="dropdown-item dropdown-toggle dropdown-menu-button">
                    <i className={`mr-2 fa-fw far fa-calendar-alt`}></i>
                    <span>Datumintervall </span>
                    <span className="ml-1 mr-1 mt-1 badge badge-light bg-primary text-white float-right">
                        {this.props.filter.dates.from !== null && this.props.filter.dates.to !== null 
                            ? `${this.props.filter.dates.from.toISOString().slice(0, 10)} - ${this.props.filter.dates.to.toISOString().slice(0, 10)}` 
                            : ""
                        }
                    </span>
                </button>
                <div className={`dropdown-menu dropdown-hide p-0 shadow`} style={{ minWidth: "70%" }}>
                    <DateRangePicker dates={this.props.filter.dates} setDateFilter={(dates) => this.onCheckChange(dates, "dates")}/>
                </div>
            </li>
        )

        /** 
         * === === === === === === === === === === === ===
         * GLOBALA FILTER
         * === === === === === === === === === === === ===
         */
        let textFilterInput = (
            <li className="pl-2 pr-2" key="text-filter">
                <input type="search" className="form-control rounded-0 border-0 p-2" data-filtertype="text-filter" placeholder="Sök..." aria-label="text-filter" onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}></input>
            </li>
        );

        /** 
         * === === === === === === === === === === === ===
         * SORTERING
         * === === === === === === === === === === === ===
         */
        let sortingOptions = (
            [
                <li className="mb-1 dropdown-item pl-2" key="sort-default">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="sorting" name="sortingRadioButton" id="sort-default" value="default" checked={this.props.filter.sorting.length <= 0} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="sort-default">Standard</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="sort-random">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="sorting" name="sortingRadioButton" id="sort-random" value="random" checked={this.props.filter.sorting.includes("random")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="sort-random">Slumpad ordning</label>
                    </div>
                </li>
            ]
        );
        if (this.props.customerType !== 'grocery' && this.props.customerType !== 'news' && this.props.customerType !== 'event') {
            sortingOptions.push(
                <li className="mb-1 dropdown-item pl-2" key="sort-price-rising">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="sorting" name="sortingRadioButton" id="sort-price-rising" value="price-rising" checked={this.props.filter.sorting.includes("price-rising")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="sort-price-rising">Pris stigande</label>
                    </div>
                </li>,
                <li className="mb-1 dropdown-item pl-2" key="sort-price-falling">
                    <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" data-filtertype="sorting" name="sortingRadioButton" id="sort-price-falling" value="price-falling" checked={this.props.filter.sorting.includes("price-falling")} onChange={(e) => this.onCheckChange(e.currentTarget.value, e.currentTarget.dataset.filtertype)}/>
                        <label className="custom-control-label" htmlFor="sort-price-falling">Pris fallande</label>
                    </div>
                </li>
            )
        }
        
        return <FilterBar 
                    filter={this.props.filter} 
                    cityFilter={cityFilter}
                    areaFilter={areaFilter} 
                    typeFilter={typeFilter} 
                    runningFilter={runningFilter} 

                    brandFilter={brandFilter}
                    modelFilter={modelFilter}
                    statusFilter={statusFilter}
                    fuelFilter={fuelFilter}
                    affiliateFilter={affiliateFilter}
                    priceFromFilter={priceFromFilter}
                    priceToFilter={priceToFilter}
                    yearFromFilter={yearFromFilter}
                    yearToFilter={yearToFilter}
                    mileageFromFilter={mileageFromFilter}
                    mileageToFilter={mileageToFilter}
                    carsMileage={carsMileageArr}

                    numObjectsFilter={numObjectsFilter}
                    offerTypeFilter={offerTypeFilter}

                    eventStatusFilter={eventStatusFilter}
                    eventDateFilter={eventDateFilter}

                    sortingOptions={sortingOptions}

                    textFilterInput={textFilterInput}
                    resetFilter={this.resetFilter} 
                    
                    customerType={this.props.customerType}
                    updateHiddenObjects={this.props.updateHiddenObjects}
                    numObjects={this.props.numObjects}
                    numFilters={this.props.numFilters}
                    onCheckChange={this.onCheckChange}
                />;
    }
}