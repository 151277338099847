import React from 'react';

export class SliderPreview extends React.Component {
    render(){
        return (
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl shadow-lg" role="document">
                    <div className="modal-content" style={{marginTop: 65}}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Förhandsgranskning</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div id="modalBody" className="modal-body" style={{minHeight: 395, maxHeight: 850, overflowY: 'auto'}}>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}