import React from 'react';
import {CampaignList} from '../components/CampaignList/CampaignList';
import {
    nadirGetObjectsFromCustomer, 
    nadirGetCampaign, 
    nadirPostCampaign
} from '../handlers/nadir';
import {
    gamGetLineItemsByCustomerId,
    gamGetCreativesByLineItemId
} from '../handlers/googleAdManager';
import {ObjectCardEstate} from '../components/CampaignList/Estate/ObjectCardEstate';
import {ObjectListEstate} from '../components/CampaignList/Estate/ObjectListEstate';
import {ObjectCardMotor} from '../components/CampaignList/Motor/ObjectCardMotor';
import {ObjectListMotor} from '../components/CampaignList/Motor/ObjectListMotor';
import {ObjectCardGrocery} from '../components/CampaignList/Grocery/ObjectCardGrocery';
import {ObjectListGrocery} from '../components/CampaignList/Grocery/ObjectListGrocery';
import {ObjectCardNews} from '../components/CampaignList/News/ObjectCardNews';
import {ObjectListNews} from '../components/CampaignList/News/ObjectListNews';
import {ObjectCardEvent} from '../components/CampaignList/Event/ObjectCardEvent';
import {ObjectListEvent} from '../components/CampaignList/Event/ObjectListEvent';
import {FilterBarContainer} from './FilterBarContainer';
import {CampaignInfo} from '../components/CampaignList/CampaignInfo';
import {StatusBar} from '../components/CampaignList/StatusBar';
import {SaveButton} from '../components/CampaignList/SaveButton';
import {SliderPreview} from '../components/CampaignList/SliderPreview';
import {
    filtersFilterObjects,
    filtersSortObjects,
    filtersFindFirstObject,
    filtersCheckHiddenObjects,
    filtersUpdateHiddenObjects,
    convertToDates
} from '../handlers/filters';
import $ from 'jquery';

const _filter = {
    city: [],
    area: [],
    type: [],
    running: [],

    brand: [],
    model: [],
    status: [],
    fuel: [],
    affiliate: [],
    price: {
        from: null,
        to: null
    },
    year: {
        from: null,
        to: null
    },
    mileage: {
        from: null,
        to: null
    },

    numObjects: null,
    offerType: [],

    dates: {
        from: null,
        to: null,
    },

    hiddenObjects: [],
    firstObjectId: null,
    textFilter: null,

    sorting: []
}

export class CampaignListContainer extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            lineItems: [],
            comingLineItems: [],
            previousLineitems: [],
            currentLineItem: '',
            objects: [],
            defaultObjects: [],
            filterSaved: false,
            filter: _filter,
            defaultFilter: _filter,
            copiedFilter: _filter,
            history: [],
            objectView: localStorage.getItem('object-view') !== null ? localStorage.getItem('object-view') : "card"
        }
        this.getLineItems = this.getLineItems.bind(this);
        this.clickLineItem = this.clickLineItem.bind(this);
        this.updateFirstObject = this.updateFirstObject.bind(this);
        this.updateHiddenObjects = this.updateHiddenObjects.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.postFilter = this.postFilter.bind(this);
        this.copyFilter = this.copyFilter.bind(this);
        this.pasteFilter = this.pasteFilter.bind(this);
        this.toggleSliderModal = this.toggleSliderModal.bind(this);
        this.changeObjectView = this.changeObjectView.bind(this);
    }

    clickLineItem(e) {
        let allListItems = document.querySelectorAll('.line-item');
        allListItems.forEach(li => {
            li.classList.remove('text-white');
            li.classList.remove('bg-primary');
            li.innerHTML = li.innerHTML.replace('fa-angle-down', 'fa-angle-right');
        });
        
        let clickedLineItem = e.currentTarget.dataset.lineitemid;
        if (clickedLineItem === this.state['currentLineItem']) {
            this.setState({ currentLineItem: '' });
        }
        else {
            e.currentTarget.classList.add('text-white')
            e.currentTarget.classList.add('bg-primary')
            e.currentTarget.innerHTML = e.currentTarget.innerHTML.replace('fa-angle-right', 'fa-angle-down');
            this.setState({ currentLineItem: clickedLineItem });
        }
    }

    getLineItems(gamId) {
        gamGetLineItemsByCustomerId(gamId)
        .then((response) => {
            this.props.loading(false)
            const lineitems = response.results;
            const runningLineitems = [];
            const comingLineitems = [];
            const previousLineitems = [];
            const now = new Date().setHours(12, 0, 0, 0);

            lineitems.sort((a, b) => {
                return new Date(a.startDate.year, a.startDate.month - 1, a.startDate.day, 12) - new Date(b.startDate.year, b.startDate.month - 1, b.startDate.day, 12);
            });
            
            lineitems.forEach(li => {
                li.id = li.id.toString();
                const startDate = new Date(li.startDate.year, li.startDate.month - 1, li.startDate.day, 12)
                const endDate = new Date(li.endDate.year, li.endDate.month - 1, li.endDate.day, 12)

                if (startDate > now) {
                    comingLineitems.push(li);
                }
                else if (endDate < now) {
                    previousLineitems.push(li);
                }
                else {
                    runningLineitems.push(li);
                }
            });
            const lastTen = previousLineitems.length > 10 ? previousLineitems.slice(previousLineitems.length - 10) : previousLineitems;
            this.setState({
                lineItems: runningLineitems,
                comingLineItems: comingLineitems,
                previousLineitems: lastTen
            });
            if (runningLineitems.length === 0 && comingLineitems.length === 0 && previousLineitems.length === 0) {
                this.props.setError({
                    show: true,
                    icon: "fas fa-folder-open",
                    message: "Kunden har inga kampanjer"
                });
            }
        });
    }

    componentDidMount() {
        if (this.props.customer && this.props.customer.gamId !== "") {
            this.props.loading(true)
            this.getLineItems(this.props.customer.gamId);
        }
        else {
            this.props.setError({
                show: true,
                message: "Kunden saknar GAM-id"
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        $('[data-popover="popover"]').popover();
        if (this.props['current-customer'] !== prevProps['current-customer']) {
            this.setState({
                lineItems: [],
                comingLineItems: [],
                previousLineitems: [],
                defaultObjects: [],
                objects: []
            });
            if (this.props.customer.gamId !== "") {
                this.props.loading(true);
                this.getLineItems(this.props.customer.gamId);
                this.props.setError({
                    show: false,
                    icon: null,
                    message: null
                })
            }
            else {
                this.props.setError({
                    show: true,
                    message: "Kunden saknar GAM-id"
                })
            }
        }

        if (this.state['currentLineItem'] !== prevState['currentLineItem']) {
            if (this.state['currentLineItem'] !== '') {
                // Om currentLineItem har ändrats, hämta objekt för detta
                const token = localStorage.getItem('token');
                nadirGetObjectsFromCustomer(token, this.props['current-customer'], (response)=>{
                    this.setState({defaultObjects: response.objects});
                    nadirGetCampaign(token, this.state.currentLineItem, (response)=>{
                        let objects;
                        if (response.status === "success") {
                            let currentHiddenObjects = response.filter.hasOwnProperty("hiddenObjects") ? filtersCheckHiddenObjects(this.state.defaultObjects, response.filter.hiddenObjects) : [];
                            let filterDates = response.filter.hasOwnProperty("dates") ? this.createDateObjects(response.filter.dates) : { from: null, to: null };
                            this.setState({
                                filter: {
                                    city: response.filter.hasOwnProperty("cities") ? response.filter.cities : [],
                                    area: response.filter.hasOwnProperty("areas") ? response.filter.areas : [],
                                    type: response.filter.hasOwnProperty("types") ? response.filter.types : [],
                                    running: response.filter.hasOwnProperty("running") ? response.filter.running : [],
                                    brand: response.filter.hasOwnProperty("brands") ? response.filter.brands : [],
                                    model: response.filter.hasOwnProperty("models") ? response.filter.models : [],
                                    status: response.filter.hasOwnProperty("status") ? response.filter.status : [],
                                    fuel: response.filter.hasOwnProperty("fuels") ? response.filter.fuels : [],
                                    affiliate: response.filter.hasOwnProperty("affiliates") ? response.filter.affiliates : [],
                                    price: response.filter.hasOwnProperty("prices") ? response.filter.prices : { from: null, to: null },
                                    year: response.filter.hasOwnProperty("years") ? response.filter.years : { from: null, to: null },
                                    mileage: response.filter.hasOwnProperty("mileages") ? response.filter.mileages : { from: null, to: null },
                                    numObjects: response.filter.hasOwnProperty("numObjects") ? response.filter.numObjects : null,
                                    offerType: response.filter.hasOwnProperty("offerTypes") ? response.filter.offerTypes : [],
                                    dates: filterDates,
                                    hiddenObjects: currentHiddenObjects,
                                    firstObjectId: response.filter.hasOwnProperty("firstObjectId") ? response.filter.firstObjectId : null,
                                    textFilter: null,
                                    sorting: response.filter.hasOwnProperty("sorting") ? response.filter.sorting : []
                                },
                                defaultFilter: {
                                    city: response.filter.hasOwnProperty("cities") ? response.filter.cities : [],
                                    area: response.filter.hasOwnProperty("areas") ? response.filter.areas : [],
                                    type: response.filter.hasOwnProperty("types") ? response.filter.types : [],
                                    running: response.filter.hasOwnProperty("running") ? response.filter.running : [],
                                    brand: response.filter.hasOwnProperty("brands") ? response.filter.brands : [],
                                    model: response.filter.hasOwnProperty("models") ? response.filter.models : [],
                                    status: response.filter.hasOwnProperty("status") ? response.filter.status : [],
                                    fuel: response.filter.hasOwnProperty("fuels") ? response.filter.fuels : [],
                                    affiliate: response.filter.hasOwnProperty("affiliates") ? response.filter.affiliates : [],
                                    price: response.filter.hasOwnProperty("prices") ? response.filter.prices : { from: null, to: null },
                                    year: response.filter.hasOwnProperty("years") ? response.filter.years : { from: null, to: null },
                                    mileage: response.filter.hasOwnProperty("mileages") ? response.filter.mileages : { from: null, to: null },
                                    numObjects: response.filter.hasOwnProperty("numObjects") ? response.filter.numObjects : null,
                                    offerType: response.filter.hasOwnProperty("offerTypes") ? response.filter.offerTypes : [],
                                    dates: filterDates,
                                    hiddenObjects: currentHiddenObjects,
                                    firstObjectId: response.filter.hasOwnProperty("firstObjectId") ? response.filter.firstObjectId : null,
                                    textFilter: null,
                                    sorting: response.filter.hasOwnProperty("sorting") ? response.filter.sorting : []
                                },
                                history: response.filter.hasOwnProperty('history') ? response.filter.history : []
                            });
                            if (this.state.filter.sorting.length !== 0) {
                                objects = filtersSortObjects(this.state.defaultObjects, this.state.filter.sorting);
                            }
                            else {
                                objects = this.state.defaultObjects;
                            }
                            if (this.state.filter.firstObjectId) {
                                objects = filtersFindFirstObject(objects, this.state.filter.firstObjectId);
                            }
                            let filteredObjects = filtersFilterObjects(objects, this.state.filter, this.props.customer.type);
                            this.setState({objects: filteredObjects});
                        }
                        else {
                            this.setState({
                                filter: {
                                    city: [],
                                    area: [],
                                    type: [],
                                    running: [],
                                    brand: [],
                                    model: [],
                                    status: [],
                                    fuel: [],
                                    affiliate: [],
                                    price: {
                                        from: null,
                                        to: null
                                    },
                                    year: {
                                        from: null,
                                        to: null
                                    },
                                    mileage: {
                                        from: null,
                                        to: null
                                    },
                                    numObjects: null,
                                    offerType: [],
                                    dates: { 
                                        from: null, 
                                        to: null 
                                    },
                                    hiddenObjects: [],
                                    firstObjectId: null,
                                    textFilter: null,
                                    sorting: []
                                },
                                defaultFilter: {
                                    city: [],
                                    area: [],
                                    type: [],
                                    running: [],
                                    brand: [],
                                    model: [],
                                    status: [],
                                    fuel: [],
                                    affiliate: [],
                                    price: {
                                        from: null,
                                        to: null
                                    },
                                    year: {
                                        from: null,
                                        to: null
                                    },
                                    mileage: {
                                        from: null,
                                        to: null
                                    },
                                    numObjects: null,
                                    offerType: [],
                                    dates: { 
                                        from: null, 
                                        to: null
                                    },
                                    hiddenObjects: [],
                                    firstObjectId: null,
                                    textFilter: null,
                                    sorting: []
                                },
                                history: [] 
                            });
                        }
                    });
                });
            }
            else {
                this.setState({
                    objects: [],
                    defaultObjects: []
                })
            }
        }

        if (this.state.filter !== prevState.filter) {
            let objects = filtersSortObjects(this.state.defaultObjects, this.state.filter.sorting);
            if (this.state.filter.firstObjectId) {
                objects = filtersFindFirstObject(objects, this.state.filter.firstObjectId);
            }
            let filteredObjects = filtersFilterObjects(objects, this.state.filter, this.props.customer.type);
            this.setState({objects: filteredObjects});
        }
    }

    createDateObjects(dates) {
        if (dates.from !== null && dates.to !== null) {
            return { from: new Date(dates.from), to: new Date(dates.to) };
        }
        else {
            return { from: null, to: null };
        }
    }

    copyFilter() {
        this.setState({
            copiedFilter: this.state.filter
        });
    }

    pasteFilter() {
        this.setState({
            filter: this.state.copiedFilter
        });
    }

    updateFilters(filters) {
        this.setState({
            filter: filters
        });
    }

    removeEndedLineitems(lineitems) {
        let activeLineitems = [];
        lineitems.forEach((li) => {
            if (!li.executionState.includes("ENDED")) {
                activeLineitems.push(li);
            }
        });
        return activeLineitems;
    }

    updateFirstObject(e) {
        let newStarredObj = e.currentTarget.dataset.uniqueid;
        if (newStarredObj !== this.state.filter.firstObjectId) {
            this.setState(prevState => {
                let filter = Object.assign({}, prevState.filter);
                filter.firstObjectId = newStarredObj;
                return { filter };
            });

            let newObjectArray = filtersFindFirstObject(this.state.defaultObjects, newStarredObj);
            this.setState({
                objects: newObjectArray
            });
        }
        else {
            this.setState({
                objects: this.state.defaultObjects,
            });
            this.setState(prevState => {
                let filter = Object.assign({}, prevState.filter);
                filter.firstObjectId = null;
                return { filter };
            });
        }
    }

    updateHiddenObjects(e) {
        let filter = JSON.parse(JSON.stringify(this.state.filter));
        let newHiddenObjects = filtersUpdateHiddenObjects(this.state.objects, filter.hiddenObjects, e.currentTarget.dataset);
        filter.hiddenObjects = newHiddenObjects;

        filter.dates = convertToDates(filter.dates);

        this.setState({
            filter: filter
        });
    }

    postFilter() {
        let filter = {
            "lineitemId": this.state['currentLineItem'],
            "customerId": this.props['current-customer'],
            "types": this.state.filter.type,
            "areas": this.state.filter.area,
            "cities": this.state.filter.city,
            "running": this.state.filter.running,
            "brands": this.state.filter.brand,
            "models": this.state.filter.model,
            "status": this.state.filter.status,
            "fuels": this.state.filter.fuel,
            "affiliates": this.state.filter.affiliate,
            "prices": this.state.filter.price,
            "years": this.state.filter.year,
            "mileages": this.state.filter.mileage,
            "numObjects": this.state.filter.numObjects,
            "offerTypes": this.state.filter.offerType,
            "dates": this.state.filter.dates,
            "hiddenObjects": this.state.filter.hiddenObjects,
            "firstObjectId": this.state.filter.firstObjectId !== null ? this.state.filter.firstObjectId : '',
            "sorting": this.state.filter.sorting
        }
        let filterHistory = JSON.parse(JSON.stringify(filter));
        const currentdate = new Date(); 
        const datetime = "" + currentdate.getFullYear() + "-"
                        + ("0" + (currentdate.getMonth()+1)).slice(-2)  + "-" 
                        + ("0" + currentdate.getDate()).slice(-2) + " "
                        + ("0" + currentdate.getHours()).slice(-2) + ":"  
                        + ("0" + currentdate.getMinutes()).slice(-2) + ":" 
                        + ("0" + currentdate.getSeconds()).slice(-2);

        filter['history'] = JSON.parse(JSON.stringify(this.state.history));
        let newChange = {
            username: this.props.user.username,
            filter: filterHistory,
            date: datetime
        }
        filter['history'].push(newChange);

        const token = localStorage.getItem('token');
        nadirPostCampaign(token, this.state['currentLineItem'], filter, (response) => {
            this.setState({
                history: filter['history'],
                filterSaved: true
            });
            setTimeout(function() { 
                this.setState({
                    defaultFilter: this.state.filter,
                    filterSaved: false
                });
            }.bind(this), 3000);
        });
    }

    toggleSliderModal() {
        let oldIframes = document.querySelectorAll("#modalBody iframe");
        let modal = document.getElementById("modalBody");
        
        if (oldIframes) {
            while (modal.firstChild) {
                modal.removeChild(modal.firstChild);
            }
        }

        let loaderDiv = document.createElement("div");
        loaderDiv.className = "text-center text-muted";
        let loaderIcon = document.createElement("i");
        loaderIcon.className = "fas fa-sync-alt fa-spin slow loader-icon"
        loaderDiv.appendChild(loaderIcon);
        modal.appendChild(loaderDiv);
        
        gamGetCreativesByLineItemId(this.state.currentLineItem)
        .then((response) => {
            modal.removeChild(modal.firstChild)
            response.forEach(creative => {
                let div = document.createElement("div");
                let h5 = document.createElement("h5");

                let headerText = "";
                if (creative.size.height === 500) {
                    if (creative.size.width === 320) {
                        headerText = "Mobil - 320x500";
                    }
                    else if (creative.size.width === 300) {
                        headerText = "Desktop - 300x500";
                    }
                }
                else {
                    if (creative.size.width === 320) {
                        headerText = "Mobil - 320x320";
                    }
                    else if (creative.size.width === 640) {
                        headerText = "Modul Large - 640x320";
                    }
                    else if (creative.size.width === 980) {
                        headerText = "Panorama - 980x360";
                    }
                }
                
                let t = document.createTextNode(headerText);
                h5.appendChild(t);
                h5.style.textAlign = "center";
                div.appendChild(h5);

                let iframe = document.createElement("iframe");
                iframe.src = creative.previewUrl;
                iframe.style.height = creative.size.height.toString() + "px";
                iframe.style.width = creative.size.width.toString() + "px";
                iframe.style.border = "none";
                iframe.style.display = "block";
                iframe.style.margin = "0px auto 20px auto";
                
                div.appendChild(iframe);
                modal.appendChild(div);
            });
        })
        .catch(response => {
            console.warn(response);
        })
    }

    clickHeader(e) {
        if (e.currentTarget.classList.contains("collapsed")) {
            e.currentTarget.innerHTML = e.currentTarget.innerHTML.replace("fa-angle-down", "fa-angle-right");
        }
        else {
            e.currentTarget.innerHTML = e.currentTarget.innerHTML.replace("fa-angle-right", "fa-angle-down");
        }
    }

    changeObjectView(e) {
        let objView = e.currentTarget.dataset.view;
        this.setState({
            objectView: objView
        });
        localStorage.setItem('object-view', objView);
    }

    render() {
        const listIsPopulated = this.state.lineItems.length > 0 || this.state.comingLineItems.length > 0 || this.state.previousLineitems.length > 0;

        const typeTranslation = {
            'broker': <i className="fas fa-home"></i>,
            'auto': <i className="fas fa-car"></i>,
            'grocery': <i className="fas fa-shopping-cart"></i>,
            'news': <i className="fas fa-bullhorn"></i>,
            'event': <i className="fas fa-calendar-check"></i>
        }
        const liIcon = this.props.customer.type ? typeTranslation[this.props.customer.type] : '';
        let lineItemList = this.state.lineItems.map((lineitem)=>{
            return (
                <div key={lineitem.id}>
                    <button className="list-group-item list-group-item-action line-item campaign-list-btn" key={lineitem.id} data-lineitemid={lineitem.id} onClick={this.clickLineItem}>
                        <i className="fas fa-angle-right fa-fw mr-2"></i>
                        {lineitem.name}
                        <span className="float-right">{liIcon}</span>
                    </button>
                    {
                        lineitem.id === this.state.currentLineItem && 
                        <CampaignInfo 
                            lineitem={lineitem} 
                            customer={this.props.customer}
                            filter={this.state.filter} 
                            copiedFilter={this.state.copiedFilter}
                            copyFilter={this.copyFilter}
                            pasteFilter={this.pasteFilter}
                            history={this.state.history}
                        />
                    }
                </div>
            )
        });
        let comingLineItemList = this.state.comingLineItems.map((lineitem) => {
            return (
                <div key={lineitem.id}>
                    <button className="list-group-item list-group-item-action line-item campaign-list-btn" key={lineitem.id} data-lineitemid={lineitem.id} onClick={this.clickLineItem}>
                        <i className="fas fa-angle-right fa-fw mr-2"></i>
                        {lineitem.name}
                        <span className="float-right">{liIcon}</span>
                    </button>
                    {
                        lineitem.id === this.state.currentLineItem && 
                        <CampaignInfo 
                            lineitem={lineitem} 
                            customer={this.props.customer}
                            filter={this.state.filter} 
                            copiedFilter={this.state.copiedFilter} 
                            copyFilter={this.copyFilter}
                            pasteFilter={this.pasteFilter}
                            history={this.state.history}
                        />
                    }
                </div>
            )
        });
        let previousLineItemList = this.state.previousLineitems.map((lineitem) => {
            return (
                <div key={lineitem.id}>
                    <button className="list-group-item list-group-item-action line-item campaign-list-btn" key={lineitem.id} data-lineitemid={lineitem.id} onClick={this.clickLineItem}>
                        <i className="fas fa-angle-right fa-fw mr-2"></i>
                        {lineitem.name}
                        <span className="float-right">{liIcon}</span>
                    </button>
                    {
                        lineitem.id === this.state.currentLineItem && 
                        <CampaignInfo 
                            lineitem={lineitem} 
                            customer={this.props.customer}
                            filter={this.state.filter} 
                            copiedFilter={this.state.copiedFilter} 
                            copyFilter={this.copyFilter}
                            pasteFilter={this.pasteFilter}
                            history={this.state.history}
                        />
                    }
                </div>
            )
        });

        let saveButton = <SaveButton postFilter={this.postFilter} filterSaved={this.state.filterSaved} />;

        let sliderPreview = <SliderPreview />;

        let hiddenObjInView = 0;
        this.state.objects.forEach((obj) => {
            if (this.state.filter.hiddenObjects.includes(obj.uniqueId)) {
                hiddenObjInView++;
            }
        });
        let numFilters = this.state.filter.area.length
                        + this.state.filter.city.length
                        + this.state.filter.type.length
                        + this.state.filter.running.length
                        + this.state.filter.brand.length
                        + this.state.filter.model.length
                        + this.state.filter.status.length
                        + this.state.filter.fuel.length
                        + this.state.filter.affiliate.length
                        + this.state.filter.offerType.length;
        numFilters = this.state.filter.year.from !== null || this.state.filter.year.to !== null ? numFilters + 1 : numFilters;
        numFilters = this.state.filter.mileage.from !== null || this.state.filter.mileage.to !== null ? numFilters + 1 : numFilters;
        numFilters = this.state.filter.price.from !== null || this.state.filter.price.to !== null ? numFilters + 1 : numFilters;
        numFilters = this.state.filter.numObjects !== null ? numFilters + 1 : numFilters;
        numFilters = this.state.filter.dates.from !== null && this.state.filter.dates.to !== null ? numFilters + 1 : numFilters;
        let statusBar = this.state.defaultObjects.length > 0 ? <StatusBar 
                                                                    objectCount={this.state.objects.length}
                                                                    allObjectsCount={this.state.defaultObjects.length}
                                                                    numFilters={numFilters}
                                                                    filterSaved={this.state.filterSaved}
                                                                    objectView={this.state.objectView}
                                                                    changeObjectView={this.changeObjectView}
                                                                    toggleSliderModal={this.toggleSliderModal}
                                                                    updateHiddenObjects={this.updateHiddenObjects}
                                                                    hiddenObjectsInView={hiddenObjInView}
                                                                    sorting={this.state.filter.sorting}
                                                                    lineitem={this.state.currentLineItem}
                                                                    userRole={this.props.userRole}
                                                                /> : '';

        let filterBar = this.state.defaultObjects.length > 0 ? <FilterBarContainer 
                                                                    defaultObjects={this.state.defaultObjects}
                                                                    customerType={this.props.customer.type}
                                                                    filter={this.state.filter}
                                                                    updateFilters={this.updateFilters}
                                                                    updateHiddenObjects={this.updateHiddenObjects}
                                                                    numObjects={this.state.objects.length}
                                                                    numFilters={numFilters}
                                                                /> : '';

        let objects = this.state.objects.map((object)=>{
            let starred = object.uniqueId === this.state.filter.firstObjectId ? true : false;
            let hidden = this.state.filter.hiddenObjects.includes(object.uniqueId) ? true : false;

            if (this.props.customer.type === "auto") {
                if (this.state.objectView === "list") {
                    return (
                        <ObjectListMotor
                            key={object.uniqueId}
                            object={object} 
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
                else {
                    return (
                        <ObjectCardMotor
                            key={object.uniqueId}
                            object={object} 
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
            }
            else if (this.props.customer.type === "grocery") {
                if (this.state.objectView === "list") {
                    return (
                        <ObjectListGrocery
                            key={object.uniqueId}
                            object={object} 
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
                else {
                    return (
                        <ObjectCardGrocery
                            key={object.uniqueId}
                            object={object} 
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
            }
            else if (this.props.customer.type === "news") {
                if (this.state.objectView === "list") {
                    return (
                        <ObjectListNews
                            key={object.uniqueId}
                            object={object} 
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
                else {
                    return (
                        <ObjectCardNews
                            key={object.uniqueId}
                            object={object} 
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
            }
            else if (this.props.customer.type === "event") {
                if (this.state.objectView === "list") {
                    return (
                        <ObjectListEvent
                            key={object.uniqueId}
                            object={object} 
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
                else {
                    return (
                        <ObjectCardEvent
                            key={object.uniqueId}
                            object={object} 
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
            }
            else {
                const typeIcons = {
                    "Villa": <i className="fas fa-home"></i>,
                    "Lägenhet": <i className="fas fa-building"></i>,
                    "Fastighet": <i className="fas fa-city"></i>,
                    "Nyproduktion": <i className="fas fa-drafting-compass"></i>,
                    "Fritidshus": <i className="fas fa-umbrella-beach"></i>,
                    "Tomt": <i className="fas fa-tree"></i>,
                    "Lantbruk": <i className="fas fa-tractor"></i>
                }
                let type = typeIcons[object.details.type];

                if (this.state.objectView === "list") {
                    return (
                        <ObjectListEstate 
                            key={object.uniqueId}
                            object={object} 
                            type={type}
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
                else {
                    return (
                        <ObjectCardEstate 
                            key={object.uniqueId}
                            object={object} 
                            type={type}
                            starred={starred}
                            hidden={hidden}
                            updateFirstObject={this.updateFirstObject}
                            updateHiddenObjects={this.updateHiddenObjects}
                        />
                    )
                }
            }
        });

        let campaignList;
        if (this.props.loggedIn && this.props['current-customer']){
            campaignList = <CampaignList 
                                customerType={this.props.customer.type}
                                lineitems={lineItemList} 
                                comingLineitems={comingLineItemList}
                                previousLineitems={previousLineItemList}
                                objects={objects}
                                objectView={this.state.objectView}
                                clickHeader={this.clickHeader}
                                filterBar={filterBar}
                                statusBar={statusBar}
                                sliderPreview={sliderPreview}
                                saveButton={
                                    (JSON.stringify(this.state.filter) !== JSON.stringify(this.state.defaultFilter)) && 
                                    (this.state.objects.length > 0) &&
                                    (this.props.userRole !== "restricted") && 
                                    saveButton}
                            />;
        } else {
            campaignList = '';
        }
        return listIsPopulated && campaignList
    }
}