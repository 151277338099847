import $ from 'jquery';

/** 
 * === === === === === === === === === === === ===
 * SORTERING
 * === === === === === === === === === === === ===
 */
export const filtersSortObjects = (objects, sorting) => {
    if (sorting.length !== 0) {
        if (sorting.includes("price-rising")) {
            objects.sort((a, b) => a.details.price - b.details.price);
        }
        else if (sorting.includes("price-falling")) {
            objects.sort((a, b) => b.details.price - a.details.price);
        }
        else if (sorting.includes("random")) {
            objects.sort((a, b) => a.sortedIndex - b.sortedIndex);
        }
    }
    else {
        objects.sort((a, b) => a.sortedIndex - b.sortedIndex);
    }
    return objects;
}

/** 
 * === === === === === === === === === === === ===
 * FILTRERING
 * === === === === === === === === === === === ===
 */
export const filtersFilterObjects = (objects, filter, customerType) => {
    let filteredObjects = [];
    for (let i = 0; i < objects.length; i++) {
        const obj = objects[i];
        let objIsGood = true;
        if (filter.city.length !== 0) {
            objIsGood = (filter.city.includes(obj.details.city) && objIsGood) ? true : false;
        }
        if (filter.area.length !== 0) {
            objIsGood = (filter.area.includes(obj.details.area) && objIsGood) ? true : false;
        }
        if (filter.type.length !== 0) {
            objIsGood = (filter.type.includes(obj.details.type) && objIsGood) ? true : false;
        }
        if (filter.running.length !== 0) {
            objIsGood = (filter.running.includes(obj.details.running) && objIsGood) ? true : false;
        }
        if (filter.brand.length !== 0) {
            objIsGood = (filter.brand.includes(obj.details.brand) && objIsGood) ? true : false;
        }
        if (filter.model.length !== 0) {
            objIsGood = (filter.model.includes(obj.details.model) && objIsGood) ? true : false;
        }
        if (filter.status.length !== 0) {
            objIsGood = (filter.status.includes(obj.details.status) && objIsGood) ? true : false;
        }
        if (filter.fuel.length !== 0) {
            objIsGood = (filter.fuel.includes(obj.details.fuel) && objIsGood) ? true : false;
        }
        if (filter.affiliate.length !== 0) {
            objIsGood = (filter.affiliate.includes(obj.details.affiliate) && objIsGood) ? true : false;
        }
        if (filter.price.from !== null) {
            objIsGood = (obj.details.price >= filter.price.from && objIsGood) ? true : false;
        }
        if (filter.price.to !== null) {
            objIsGood = (obj.details.price <= filter.price.to && objIsGood) ? true : false;
        }
        if (filter.year.from !== null) {
            objIsGood = (obj.details.year >= filter.year.from && objIsGood) ? true : false;
        }
        if (filter.year.to !== null) {
            objIsGood = (obj.details.year <= filter.year.to && objIsGood) ? true : false;
        }
        if (filter.mileage.from !== null) {
            objIsGood = (obj.details.mileage >= filter.mileage.from && objIsGood) ? true : false;
        }
        if (filter.mileage.to !== null) {
            objIsGood = (obj.details.mileage <= filter.mileage.to && objIsGood) ? true : false;
        }
        if (filter.textFilter !== null && filter.textFilter !== "") {
            if (customerType === "broker") {
                objIsGood = (obj.details.address.toLowerCase().includes(filter.textFilter.toLowerCase()) && objIsGood) ? true : false;
            }
            else {
                objIsGood = (obj.details.name.toLowerCase().includes(filter.textFilter.toLowerCase()) && objIsGood) ? true : false;
            }
        }
        if (filter.numObjects !== null) {
            objIsGood = (obj.details.typeIndex < filter.numObjects && objIsGood) ? true : false;
        }
        if (filter.offerType.length !== 0) {
            objIsGood = (filter.offerType.includes(obj.details.offerType) && objIsGood) ? true : false;
        }
        if (filter.hasOwnProperty("dates") && filter.dates.from !== null && filter.dates.to !== null) {
            objIsGood = (
                new Date(obj.details.startDate).toISOString().slice(0, 10) <= filter.dates.to.toISOString().slice(0, 10) && 
                filter.dates.from.toISOString().slice(0, 10) <= new Date(obj.details.endDate).toISOString().slice(0, 10) && 
                objIsGood
            ) ? true : false;
        }
        if (objIsGood) {
            filteredObjects.push(obj);
        }
    }
    return filteredObjects;
}

/** 
 * === === === === === === === === === === === ===
 * STJÄRNMARKERING
 * === === === === === === === === === === === ===
 */
export const filtersFindFirstObject = (objects, firstObjectId) => {
    let firstObject;
    let firstObjectPosition;
    let newOrder = [];
    let foundFirstObject = false;
    let returnArray = [];
    // Gör en ren kopia av objects som vi kan arbeta på
    let objects_clone = JSON.parse(JSON.stringify(objects));
    
    objects_clone.forEach((obj, i) => {
        if (obj.uniqueId === firstObjectId) {
            firstObject = obj;
            firstObjectPosition = i;
            foundFirstObject = true;
        }
    });
    
    if (foundFirstObject) {
        objects_clone.splice(firstObjectPosition, 1);
        newOrder.push(firstObject);
        returnArray = newOrder.concat(objects_clone);
        return returnArray;
    }
    else {
        return objects;
    }
}

/** 
 * === === === === === === === === === === === ===
 * DOLDA OBJEKT
 * === === === === === === === === === === === ===
 */
export const filtersCheckHiddenObjects = (objects, hiddenObjects) => {
    let currentHiddenObjects = [];
    objects.forEach((obj) => {
        if (hiddenObjects.includes(obj.uniqueId)) {
            currentHiddenObjects.push(obj.uniqueId);
        }
    });
    return currentHiddenObjects;
}

export const filtersUpdateHiddenObjects = (objects, hiddenObjects, event) => {
    if (event.hasOwnProperty("uniqueid")) {
        let currentObj = event.uniqueid;
        if (!hiddenObjects.includes(currentObj)) {
            hiddenObjects.push(currentObj);
        }
        else {
            hiddenObjects = hiddenObjects.filter(obj => obj !== currentObj);
        }
    }
    else {
        if (event.showhide === "hide-all") {
            for (let i = 0; i < objects.length; i++) {
                if (!hiddenObjects.includes(objects[i].uniqueId)) {
                    hiddenObjects.push(objects[i].uniqueId);
                }
            }
        }
        else if (event.showhide === "show-all") {
            for (let i = 0; i < objects.length; i++) {
                hiddenObjects = hiddenObjects.filter(obj => obj !== objects[i].uniqueId);
            }
        }
    }
    return hiddenObjects;
}

/** 
 * === === === === === === === === === === === ===
 * FILTERBAR
 * === === === === === === === === === === === ===
 */
export const filtersResetFilter = (e, propsFilter) => {
    let filter = JSON.parse(JSON.stringify(propsFilter));
    let reset = e.currentTarget.dataset.reset;

    let dates = convertToDates(filter.dates);

    if (reset === "filterbar") {
        filter.city = [];
        filter.area = [];
        filter.type = [];
        filter.running = [];
        filter.brand = [];
        filter.model = [];
        filter.fuel = [];
        filter.affiliate = [];
        filter.price = { from: null, to: null };
        filter.year = { from: null, to: null };
        filter.mileage = { from: null, to: null };
        filter.status = [];
        filter.numObjects = null;
        filter.offerType = [];
        filter.dates = { from: null, to: null };
        filter.textFilter = null;
        filter.sorting = [];

        $(".search-input").val(null);
    }
    else if (reset === "hidden") {
        filter.hiddenObjects = [];
        filter.dates = dates;
    }
    else if (reset === "starred") {
        filter.firstObjectId = null;
        filter.dates = dates;
    }
    else if (reset === "all") {
        filter.city = [];
        filter.area = [];
        filter.type = [];
        filter.running = [];
        filter.brand = [];
        filter.model = [];
        filter.status = [];
        filter.fuel = [];
        filter.affiliate = [];
        filter.price = { from: null, to: null };
        filter.year = { from: null, to: null };
        filter.mileage = { from: null, to: null };
        filter.numObjects = null;
        filter.offerType = [];
        filter.dates = { from: null, to: null };
        filter.hiddenObjects = [];
        filter.firstObjectId = null;
        filter.textFilter = null;
        filter.sorting = [];

        $(".search-input").val(null);
    }

    return filter;
}

export const filtersCheckChange = (value, filtertype, propsFilter) => {
    let filter = JSON.parse(JSON.stringify(propsFilter));

    let cities = filter.city;
    let areas = filter.area;
    let types = filter.type;
    let running = filter.running;
    let brands = filter.brand;
    let models = filter.model;
    let status = filter.status;
    let fuels = filter.fuel;
    let affiliates = filter.affiliate;
    let prices = filter.price;
    let years = filter.year;
    let mileages = filter.mileage;
    let textFilter = filter.textFilter;
    let numObjects = filter.numObjects;
    let offerTypes = filter.offerType;
    let dates = convertToDates(filter.dates)
    let sorting = filter.sorting;

    if (filtertype !== "running" && filtertype !== "status" && filtertype !== "sorting") {
        if (filtertype === "city") {
            !cities.includes(value) ? cities.push(value) : cities = cities.filter(city => city !== value);
        }
        else if (filtertype === "area") {
            !areas.includes(value) ? areas.push(value) : areas = areas.filter(area => area !== value);
        }
        else if (filtertype === "type") {
            !types.includes(value) ? types.push(value) : types = types.filter(type => type !== value);
        }
        else if (filtertype === "brand") {
            !brands.includes(value) ? brands.push(value) : brands = brands.filter(brand => brand !== value);
        }
        else if (filtertype === "model") {
            !models.includes(value) ? models.push(value) : models = models.filter(model => model !== value);
        }
        else if (filtertype === "fuel") {
            !fuels.includes(value) ? fuels.push(value) : fuels = fuels.filter(fuel => fuel !== value);
        }
        else if (filtertype === "affiliate") {
            !affiliates.includes(value) ? affiliates.push(value) : affiliates = affiliates.filter(affiliate => affiliate !== value);
        }
        else if (filtertype === "price-from") {
            value = parseInt(value)
            prices.from = prices.from !== value ? value : null;
        }
        else if (filtertype === "price-to") {
            value = parseInt(value);
            prices.to = prices.to !== value ? value : null;
        }
        else if (filtertype === "year-from") {
            value = parseInt(value)
            years.from = years.from !== value ? value : null;
        }
        else if (filtertype === "year-to") {
            value = parseInt(value);
            years.to = years.to !== value ? value : null;
        }
        else if (filtertype === "mileage-from") {
            value = parseInt(value)
            mileages.from = mileages.from !== value ? value : null;
        }
        else if (filtertype === "mileage-to") {
            value = parseInt(value);
            mileages.to = mileages.to !== value ? value : null;
        }
        else if (filtertype === "numObjects") {
            value = parseInt(value)
            numObjects = numObjects !== value ? value : null;
        }
        else if (filtertype === "offerType") {
            !offerTypes.includes(value) ? offerTypes.push(value) : offerTypes = offerTypes.filter(offerType => offerType !== value);
        }
        else if (filtertype === "text-filter") {
            textFilter = textFilter !== value ? value : null;
        }
        else if (filtertype === "dates") {
            dates = value;
        }
    }
    else if (filtertype === "running") {
        if (value === "all") {
            running = [];
        }
        else if (value === "headStart") {
            running = ["headStart"];
        }
        else if (value === "normal") {
            running = ["normal"];
        }
    }
    else if (filtertype === "status") {
        if (value === "all") {
            status = [];
        }
        else {
            status = [value];
        }
    }
    else if (filtertype === "sorting") {
        if (value === "default") {
            sorting = [];
        }
        else if (value === "price-rising") {
            sorting = ["price-rising"];
        }
        else if (value === "price-falling") {
            sorting = ["price-falling"];
        }
        else if (value === "random") {
            sorting = ["random"];
        }
    }
    
    let newFilter = {
        city: cities,
        area: areas,
        type: types,
        running: running,
        brand: brands,
        model: models,
        status: status,
        fuel: fuels,
        affiliate: affiliates,
        price: prices,
        year: years,
        mileage: mileages,
        numObjects: numObjects,
        offerType: offerTypes,
        dates: dates,
        hiddenObjects: filter.hiddenObjects,
        firstObjectId: filter.firstObjectId,
        textFilter: textFilter,
        sorting: sorting
    }
    return newFilter;
}

export const convertToDates = (dates) => {
    return dates.from !== null && dates.to !== null 
        ? { from: new Date(dates.from), to: new Date(dates.to) } 
        : { from: dates.from, to: dates.to };
}