import React from 'react';
import {Admin} from '../components/Admin/Admin';
import { 
    nadirPostCustomer,
    nadirGetUsers,
    nadirPutUser,
    nadirGetCustomers,
    nadirDeleteCustomer,
    nadirPostUser
} from '../handlers/nadir';
import $ from 'jquery';

export class AdminContainer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            customerView: '',
            customer: null,
            user: null,
            filteredCustomers: this.props.customers,
            filteredUsers: this.props.users,
            searchFilterResults: []
        }

        this.renderCustomer = this.renderCustomer.bind(this);
        this.renderUser = this.renderUser.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.createCustomer = this.createCustomer.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.filterItems = this.filterItems.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.deleteCustomer = this.deleteCustomer.bind(this);
        this.changeType = this.changeType.bind(this);
        this.changeRules = this.changeRules.bind(this);
        this.searchCustomers = this.searchCustomers.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.setCustomer = this.setCustomer.bind(this);
        this.findCustomerById = this.findCustomerById.bind(this);
        this.putUser = this.putUser.bind(this);
        this.colorChange = this.colorChange.bind(this);
        this.createUser = this.createUser.bind(this);
    }

    renderCustomer(e){
        const customerId = e.currentTarget.dataset.customerid;
        const propCopy = JSON.parse(JSON.stringify(this.props.customers));
        propCopy.forEach((customer)=>{
            if (customer.customerId === customerId) {
                this.setState({customer: customer});
            }
        });
    }

    renderUser(e) {
        const userId = e.currentTarget.dataset.userid;
        const propCopy = JSON.parse(JSON.stringify(this.props.users));
        propCopy.forEach((user) => {
            if (user._id.$oid === userId) {
                this.setState({ user: user });
            }
        });
    }

    componentDidMount() {
        this.props.setError({
            show: false,
            icon: null,
            message: null,
        })
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.customers !== this.props.customers){
            this.setState({
                filteredCustomers: this.props.customers
            })
        }
        if (prevProps.users === null && this.props.route === 'users') {
            const token = localStorage.getItem('token');
            nadirGetUsers(token, users => {
                this.setState({
                    filteredUsers: users.users
                });
            });
        }
        if (this.props.route !== prevProps.route) {
            this.setState({
                customer: null,
                user: null
            })
        }
        if (this.state.customer) {
            if (prevState.customer && prevState.customer.customerId === this.state.customer.customerId && prevState.customer.type !== this.state.customer.type) {
                const customerCopy = JSON.parse(JSON.stringify(this.state.customer));
                customerCopy.rules = {};
                this.setState({
                    customer: customerCopy
                });
            }
        }
    }


    updateCustomer(e){
        const val = e.currentTarget.value;
        const key = e.currentTarget.dataset.key
        const updatedCustomer = this.state.customer;
        updatedCustomer[key] = val;
        this.setState({ customer: updatedCustomer });
    }

    updateUser(e) {
        const val = e.currentTarget.value;
        const key = e.currentTarget.dataset.key
        const updatedUser = this.state.user;
        updatedUser[key] = val;
        this.setState({ user: updatedUser });
    }

    putUser(e){
        const data = {
            "role": this.state.user.role,
            "products": this.state.user.products
        }
        const userId = this.state.user._id.$oid;
        const token = localStorage.getItem('token');
        nadirPutUser(token, userId, data, response => {
            if (response.status === 'OK' || response.status === 'success') {
                this.props.refreshUsers();
            }
        });
    }

    createUser(e) {
        const username = document.getElementById('createUserUsername').value;
        const password = document.getElementById('createUserPassword').value;
        const token = localStorage.getItem('token');
        nadirPostUser(token, username, password, response=>{
            if (response.status === 'OK' || response.status === 'success') {
                nadirGetUsers(token, (users) => {
                    this.props.updateUsers(users.users);
                    this.setState({
                        filteredUsers: this.props.users
                    })
                    const saveButton = document.getElementById('saveUserButton');
                    saveButton.innerHTML = '<span class="animated fadeIn fast"><i class="fas fa-check-circle"></i> Skapad!</span>';
                    setTimeout(()=>{
                        $('#createUserModal').modal('hide')
                    }, 1000)
                });
            }
        });
    }

    createCustomer(e) {
        const customer = {
            name: "",
            customerId: "",
            gamId: "",
            logoUrl: "https://digital.ntm.eu/cdn/img/aura.jpg",
            type: "",
            office: "",
            color: "rgba(0,0,0,0.7)",
            colorObj: {
                r: 0,
                g: 0,
                b: 0,
                a: 0.7
            },
            owner: "",
            rules: {}
        }
        this.setState({customer: customer});
    }

    deleteCustomer(e){
        const token = localStorage.getItem('token');
        nadirDeleteCustomer(token, this.state.customer, response => {
            this.props.refreshCustomers();
            this.setState({ 
                customer: null,
                customerView: '',
                filteredCustomers: this.props.customers
            });
            $('#confirmDelete').modal('hide')
        });
    }

    colorChange(color, e){
        const customerCopy = JSON.parse(JSON.stringify(this.state.customer));
        const rgbaString = 'rgba(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')';
        customerCopy['color'] = rgbaString;
        customerCopy['colorObj'] = color.rgb;
        this.setState({
            customer: customerCopy
        })
    }

    filterItems(e){
        let filter = e.currentTarget.value.toLowerCase();
        if (this.props.route === 'customers'){
            let newFilteredCustomers = this.props.customers.filter(customer => customer.name.toLowerCase().includes(filter))
            this.setState({filteredCustomers: newFilteredCustomers});
        } else if (this.props.route === 'users'){
            let newFilteredUsers = this.props.users.filter(user => user.username.toLowerCase().includes(filter))
            this.setState({filteredUsers: newFilteredUsers});
        }
    }

    onCheckChange(e) {
        let value = e.currentTarget.value;
        if (value === "all") {
            $('.customer-activity').each(function() {
                $(this).removeClass("customer-activity-hide");
            });
        }
        else if (value === "active") {
            $('.customer-activity').each(function() {
                if (!$(this).hasClass("customer-activity-active")) {
                    $(this).addClass("customer-activity-hide");
                }
                else {
                    $(this).removeClass("customer-activity-hide");
                }
            });
        }
        else if (value === "inactive") {
            $('.customer-activity').each(function() {
                if ($(this).hasClass("customer-activity-active")) {
                    $(this).addClass("customer-activity-hide");
                }
                else {
                    $(this).removeClass("customer-activity-hide");
                }
            });
        }
    }

    searchCustomers(e){
        let filter = e.currentTarget.value.toLowerCase();
        let searchResults = this.props.customers.filter(customer => customer.name.toLowerCase().includes(filter))
        this.setState({ searchFilterResults: searchResults });
    }

    setCustomer(e){
        const customerId = e.currentTarget.dataset.customerid;
        let userCopy = JSON.parse(JSON.stringify(this.state.user));
        try {
            userCopy['products']['aura']['customerId'] = customerId;
        } catch (error) {
            userCopy['products'] = {
                aura: {
                    customerId: customerId
                }
            }
        }
        this.setState({user: userCopy})
        const userCustomerInput = document.getElementById('userCustomer');
        const customerObject = this.findCustomerById(customerId)
        userCustomerInput.placeholder = customerObject.name;
        userCustomerInput.value = '';
    }

    clearSearch(){
        this.setState({searchFilterResults: []});
    }

    findCustomerById(id){
        let foundCustomer = false;
        this.props.customers.forEach(customer=>{
            if (customer.customerId === id){
                foundCustomer = customer;
            }
        });
        return foundCustomer;
    }

    saveCustomer(e){
        const token = localStorage.getItem('token');
        nadirPostCustomer(token, this.state.customer, (response)=>{
            if (response.status === 'success'){
                nadirGetCustomers(token, (customers)=>{
                    this.props.updateCustomers(customers);
                    this.props.refreshCustomers();
                    this.setState({
                        filteredCustomers: this.props.customers
                    })
                });
            }
        });
    }

    changeType(e){
        const type = e.currentTarget.dataset.type;
        const form = e.currentTarget.dataset.form;
        if (form === "customer"){
            let customerCopy = JSON.parse(JSON.stringify(this.state.customer));
            customerCopy.type = type;
            this.setState({
                customer: customerCopy
            });
        } else if (form === "user"){
            let userCopy = JSON.parse(JSON.stringify(this.state.user));
            userCopy.role = type;
            this.setState({
                user: userCopy
            });
        }
    }

    changeRules(e) {
        let customerCopy = JSON.parse(JSON.stringify(this.state.customer));
        const ruleType = e.currentTarget.dataset.ruletype;

        if (!customerCopy.hasOwnProperty("rules")) {
            customerCopy.rules = {}
        }
        
        if (ruleType === "object-limit") {
            const limit = parseInt(e.currentTarget.dataset.limit);
            customerCopy.rules.objectLimit = limit !== 999 ? limit : null;
        }
        else if (ruleType === "offer-types") {
            const type = e.currentTarget.value;
            if (!customerCopy.rules.hasOwnProperty("offerTypes")) {
                customerCopy.rules.offerTypes = [];
            }
            !customerCopy.rules.offerTypes.includes(type) ? customerCopy.rules.offerTypes.push(type) : customerCopy.rules.offerTypes = customerCopy.rules.offerTypes.filter(offerType => offerType !== type);
        }
        else if (ruleType === "flyer") {
            if (customerCopy.rules.hasOwnProperty("showFlyer")) {
                customerCopy.rules.showFlyer = !customerCopy.rules.showFlyer;
            }
            else {
                customerCopy.rules.showFlyer = true;
            }
        }

        this.setState({
            customer: customerCopy
        });
    }

    render(){
        let message = this.props.route === 'users' ? 'Users' : 'Customers';
        let list = [];

        // Klickade på Kunder
        if (this.props.route === 'customers'){
            list = this.state.filteredCustomers.map((customer)=>{
                const icons = {
                    'broker': <i className="fas fa-home mr-1"></i>,
                    'auto': <i className="fas fa-car mr-1"></i>,
                    'grocery': <i className="fas fa-shopping-cart mr-1"></i>,
                    'news': <i className="fas fa-bullhorn mr-1"></i>,
                    'event': <i className="fas fa-calendar-check mr-1"></i>
                }
                return (
                    <button 
                        className="list-group-item list-group-item-action line-item" 
                        key={customer.customerId} 
                        data-customerid={customer.customerId} 
                        data-customer={customer}
                        onClick={this.renderCustomer}
                    >
                        {icons[customer.type]} {customer.name}
                    </button>
                )
            });
            const listAdd = (
                <button
                    className="list-group-item list-group-item-action line-item"
                    key="addCustomer"
                    onClick={this.createCustomer}
                >
                    <i className="fas fa-plus-circle text-primary mr-3"></i> Lägg till kund
                </button>
            );
            list.push(listAdd);
        }

        // Klickade på Användare
        if (this.props.route === 'users' && this.state.filteredUsers){
            list = this.state.filteredUsers.map((user) => {
                return (
                    <button
                        className="list-group-item list-group-item-action line-item"
                        key={user._id['$oid']}
                        data-userid={user._id['$oid']}
                        data-user={user}
                        onClick={this.renderUser}
                    >
                        <i className="fas fa-user mr-2"></i> {user.username}
                    </button>
                )
            });
            const listAdd = (
                <button
                    className="list-group-item list-group-item-action line-item"
                    key="addUser"
                    data-toggle="modal" 
                    data-target="#createUserModal"
                >
                    <i className="fas fa-plus-circle text-primary mr-3"></i> Lägg till användare
                </button>
            );
            list.push(listAdd);

        }

        let filterList;
        filterList = this.state.searchFilterResults.map((customer) => {
            const icons = {
                'broker': <i className="fas fa-home mr-1"></i>,
                'auto': <i className="fas fa-car mr-1"></i>,
                'grocery': <i className="fas fa-shopping-cart mr-1"></i>,
                'news': <i className="fas fa-bullhorn mr-1"></i>,
                'event': <i className="fas fa-calendar-check mr-1"></i>
            }
            return (
                <button
                    className="list-group-item list-group-item-action line-item"
                    key={customer.customerId}
                    data-customerid={customer.customerId}
                    data-customer={customer}
                    onClick={this.setCustomer}
                >
                    {icons[customer.type]} {customer.name}
                </button>
            )
        });
        let showView = this.state.customer ? true : false;
        return (
            <Admin 
                message={message}
                list={list}
                customer={this.state.customer}
                user={this.state.user}
                showView={showView}
                updateCustomer={this.updateCustomer}
                updateUser={this.updateUser}
                saveCustomer={this.saveCustomer}
                filterItems={this.filterItems}
                deleteCustomer={this.deleteCustomer}
                changeType={this.changeType}
                changeRules={this.changeRules}
                route={this.props.route}
                users={this.props.users}
                searchFilterResults={this.state.searchFilterResults}
                searchCustomers={this.searchCustomers}
                filterList={filterList}
                clearSearch={this.clearSearch}
                customers={this.props.customers}
                putUser={this.putUser}
                colorChange={this.colorChange}
                createUser={this.createUser}
                onChange={this.onCheckChange}
            />)
    }
}