import React from 'react';

export class Error extends React.Component {
    render(){
        const icon = this.props.error.icon ? this.props.error.icon : "fas fa-exclamation-triangle"
        return (
            <div className="container text-center animated text-muted fadeIn" style={{marginTop: "10%"}}>
                <i className={icon} style={{fontSize: 120}}></i>
                <h2 className="mt-3">{this.props.error.message}</h2>
            </div>
        )
    }
}