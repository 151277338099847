import React from 'react';

const cardListStyle = {
    overflowY: "hidden",
    overflowX: "hidden",
    height: "100%"
}


export class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    
    componentDidMount(){
        document.addEventListener('mousedown', this.handleClick, false);
    }
    
    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClick, false);
    }
    
    handleClick = (e)=>{
        if (this.hasOwnProperty('node') && this.node !== null && this.node.contains(e.target)) {
            // klickade inuti sök-listan            
            return;
        }
        // Klick utanför listan
        if (this.props.searchFilterResults.length > 0) {
            // om det finns några sökresultat redan ska dessa rensas
            this.props.clearSearch();
        }
    }


    render() {
        const popStyle = {
            zIndex: 99,
            overflowY: 'hidden',
            maxHeight: 300,
            left: 125,
            marginTop: -5
        }
        let saveButtonState = 'disabled';
        if (this.props.user.username !== "" && this.props.user._id.$oid !== "") {
            saveButtonState = '';
        }

        const searchPopUp = (
            <div className="popup-list w-50 container position-absolute border rounded shadow bg-white p-1 list-group list-group-flush" style={popStyle} ref={node => this.node = node}>
                {this.props.filterList}
            </div>
        )
        let selectedCustomer;
        try {
            this.props.customers.forEach(customer => {
                if (this.props.user.products.aura.customerId === customer.customerId){
                    selectedCustomer = customer.name;
                }
            });
        } catch (error) {
            console.log('Kunde inte hitta någon Aura-produkt kopplad till användaren:')
            selectedCustomer = '';
        }    


        return (
            <div className="bg-light p-4 col" style={cardListStyle}>
                <div className="input-group input-group-lg mb-2">
                    <div className="input-group-prepend" style={{ width: 100 }}>
                        <span className="input-group-text w-100" id="inputGroup-sizing-lg">Namn</span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-lg"
                        placeholder={this.props.user.username}
                        data-key="username"
                        onChange={this.props.updateUser} />

                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend" style={{ width: 100 }}>
                        <span className="input-group-text w-100" id="inputGroup-sizing-lg">Kund</span>
                    </div>
                    <input
                        id="userCustomer"
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-lg"
                        data-key="username"
                        placeholder={selectedCustomer}
                        onChange={this.props.searchCustomers}
                    ></input>
                </div>
                    {this.props.filterList.length > 0 && searchPopUp}
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: 150 }}>
                        {this.props.typeName}
                    </button>
                    <div className="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton">
                        <button className="dropdown-item" data-type="user" data-form="user" onClick={this.props.changeType}><i className="fas fa-user"></i> Användare</button>
                        <button className="dropdown-item" data-type="restricted" data-form="user" onClick={this.props.changeType}><i className="fas fa-user-lock"></i> Begränsad</button>
                        <button className="dropdown-item" data-type="admin" data-form="user" onClick={this.props.changeType}><i className="fas fa-user-shield"></i> Admin</button>
                    </div>
                </div>
                <button
                    type="button"
                    className="btn btn-danger text-white delete"
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: 10
                    }}
                    data-toggle="modal"
                    data-target="#confirmDelete"
                    data-backdrop="true"
                    tabIndex='-1'
                >
                    <i className="fas fa-trash-alt"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-success"
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        right: 10
                    }}
                    onClick={this.props.putUser}
                    disabled={saveButtonState}
                >
                    Spara
                        </button>
            </div>
        )
    }
}
