import React from "react";

export class FilterSubmenuItem extends React.Component {
    render() {
        return (
            <li className="mb-1 dropdown-item pl-2">
                <div className="custom-control custom-checkbox">
                    <input 
                        type="checkbox" 
                        className="custom-control-input filter-checkbox" 
                        data-filtertype={this.props.filterType} 
                        id={this.props.filterType + "-filter-" + this.props.i} 
                        value={this.props.filterItem} 
                        onChange={this.props.onChange} 
                        checked={this.props.filter.includes(this.props.filterItem)} 
                    />
                    <label 
                        className="custom-control-label" 
                        htmlFor={this.props.filterType + "-filter-" + this.props.i} 
                    >{this.props.filterItem}</label>
                </div>
            </li>
        );
    }
}