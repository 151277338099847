import React from 'react';

export class ObjectListGrocery extends React.Component {
    render(){
        const object = this.props.object;
        const starred = this.props.starred;
        const hidden = this.props.hidden;

        const fadeOnLoad = (e) => {
            e.currentTarget.classList.add('fadeIn')
        } 

        let type = object.details.type ? <span className="text-muted">{object.details.type}</span> : "";
        
        return (
            <tr className={`animated fadeIn ${hidden ? "hide-object" : ""}`} key={object.uniqueId} style={{height: "81px"}}>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "150px"}}>
                    <a className="w-100" href={object.details.link} target="_blank" rel="noopener noreferrer">
                        <img src={object.details.image} alt={object.details.name} className="animated" onLoad={fadeOnLoad} style={{ width: "100px", maxHeight: "66px", objectFit: "cover" }}/>
                    </a>
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "350px"}}>
                    <strong>{object.details.name}</strong>
                </td>
                <td className={`pl-1 pb-1 pt-1 pr-3 ${hidden ? "hide-object" : ""}`} style={{width: "400px"}}>
                    <span className="description-text">{object.details.description}</span>
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "200px"}}>
                    {type} {object.details.type && <br/>}
                    {object.details.offerType === "member" && <span className="text-muted">Medlemserbjudande</span>}
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`}>
                    <strong>{object.details.price}</strong>
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "145px"}}>
                    <button className={`btn btn-link text-primary p-0 star-btn star-object-btn ${starred ? "obj-starred" : ""}`} onClick={this.props.updateFirstObject} data-uniqueid={object.uniqueId}
                    data-container="body" data-popover="popover" data-trigger="hover" data-placement="left" data-content={starred ? `Återställ objektets placering` : `Placera objektet först`}>
                        {starred ? <i className="fas fa-star text-warning"></i> : <i className="far fa-star"></i>}
                    </button>
                    <br/>
                    <button className="btn btn-link hide-object-btn text-primary p-0" onClick={this.props.updateHiddenObjects} data-uniqueid={object.uniqueId}
                    data-container="body" data-popover="popover" data-trigger="hover" data-placement="left" data-content={hidden ? `Visa objektet` : `Dölj objektet`}>
                        {hidden ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                    </button>
                </td>
            </tr>
        )
    }
}