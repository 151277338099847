import React from 'react';

export class BackgroundLoader extends React.Component {
	render(){
		return (
			<div className="container text-center animated text-muted fadeIn loader-icon">
				<i className="fas fa-sync-alt fa-spin slow" style={{display:'block'}}></i>
			</div>
		)
	}
}