import React from "react";

export class FilterSubmenuRangeItem extends React.Component {
    render() {
        return (
            <li className="mb-1 dropdown-item pl-2">
                <div className="custom-control custom-checkbox">
                    <input 
                        type="checkbox"
                        className="custom-control-input"
                        data-filtertype={this.props.filterType}
                        name={this.props.filterType + "-checkbox"}
                        id={this.props.filterType + "-filter-" + this.props.i}
                        value={this.props.filterItem}
                        onChange={this.props.onChange}
                        checked={this.props.filter === this.props.filterItem}
                        disabled={this.props.disabled}
                    />
                    <label 
                        className="custom-control-label"
                        htmlFor={this.props.filterType + "-filter-" + this.props.i}
                    >{this.props.label}</label>
                </div>
            </li>
        );
    }
}