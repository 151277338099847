import React from "react";

const submenuStyle = {
    minWidth: '70%'    
}

export class FilterMenuItem extends React.Component {
    render() {
        return (
            <li className="dropdown-submenu">
                <button className="dropdown-item dropdown-toggle dropdown-menu-button">
                    <i className={`mr-2 fa-fw ${this.props.icon}`}></i>
                    <span>{this.props.label} </span>
                    <span className="ml-1 mr-1 mt-1 badge badge-light bg-primary text-white float-right">{Array.isArray(this.props.filter) ? this.props.filter.length > 0 ? this.props.filter.length : '' : this.props.filter !== null ? this.props.filter + ' objekt' : ''}</span>
                </button>
                <ul className={`dropdown-menu dropdown-hide p-0 shadow ${this.props.scrollable ? "scrollable" : ""}`} style={submenuStyle}>
                    {this.props.filterItems}
                </ul>
            </li>
        );
    }
}