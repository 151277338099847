import React from 'react';

export class ObjectCardEstate extends React.Component {
    render(){
        const object = this.props.object;
        const type = this.props.type;
        const starred = this.props.starred;
        const hidden = this.props.hidden;

        const cardCoverStyle = { 
            background: 'rgba(0,0,0,0.3)', 
            opacity: 0, 
            height: 200,
            position: 'absolute'
        }

        const fadeOnLoad = (e) => {
            e.currentTarget.classList.add('fadeIn')
        } 

        let livingArea = object.details.livingArea ? <span className="mr-3"><strong>{object.details.livingArea}</strong> <span className="text-muted">m²</span></span> : "";
        let rooms = object.details.rooms ? <span><strong>{object.details.rooms}</strong> <span className="text-muted">rok</span></span> : "";
        let price = object.details.price ? object.details.price.toLocaleString('se') : "";
        let exhibition = object.details.nextExhibition ? 
            (
                <span>
                    <span className="text-muted">Visning: </span>
                    <strong>{new Date(object.details.nextExhibition).toLocaleString('sv-SE', { timeZone: 'UTC', dateStyle: 'short' })}</strong> 
                    {/* <span className="text-muted"> kl. </span> 
                    <strong>{new Date(object.details.nextExhibition).toLocaleString('sv-SE', { timeZone: 'UTC', timeStyle: 'short' })}</strong> */}
                </span>
            ) 
            : "";

        return (
            <div className="
                    mb-3
                    col-sm-6 
                    col-md-6 
                    col-lg-4 
                    col-xl-3
                    animated fadeIn
                    card-row" key={object.uniqueId}>
            <div className={`card mt-3 mr-0 ml-0 shadow-sm h-100 ${hidden ? "hide-object" : ""}`}>
                <img src={object.details.image} alt={object.details.address} className="card-img-top img-fluid animated" onLoad={fadeOnLoad} style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                <a className="card-cover w-100" style={cardCoverStyle} href={object.details.link} target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-globe-europe mt-5"></i><br />
                    <p className="w-100 text-center text-white">Öppna objektsida</p>
                </a>
                <div className="card-img-overlay" style={{height: 200, pointerEvents: 'none'}}>
                        {object.details.running === "headStart" && <h5><span className="headstart-badge badge bg-orange text-white font-weight-normal float-right"><i className="fas fa-sign mr-2"></i>Förhandsvisning</span></h5>}
                </div>
                <div className="card-body">
                    <h5 className="card-title">{object.details.address}</h5>
                    <h6 className="card-subtitle mb-1 text-muted">{object.details.city}</h6>
                    <p className="card-subtitle mb-5 text-muted">{object.details.area}</p>
                    <p className="card-subtitle mb-2 text-muted">{type} {object.details.type}</p>
                    <p className="card-text">
                        {livingArea}
                        {rooms}
                        {exhibition && <br/>} {exhibition}
                    </p>
                    <p className="card-text"></p>
                </div>
                <div className="card-footer bg-secondary">
                    <h5 className="text-white mb-0">
                        {price} {object.details.price && "kr"}
                        <button className={`btn btn-link text-white float-right p-0 ml-2 star-object-btn ${starred ? "obj-starred" : ""}`} onClick={this.props.updateFirstObject} data-uniqueid={object.uniqueId}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content={starred ? `Återställ objektets placering` : `Placera objektet först`}>
                            {starred ? <i className="fas fa-star text-warning"></i> : <i className="far fa-star"></i>}
                        </button>
                        <button className="btn btn-link hide-object-btn p-0 float-right text-white" onClick={this.props.updateHiddenObjects} data-uniqueid={object.uniqueId}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content={hidden ? `Visa objektet` : `Dölj objektet`}>
                            {hidden ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                        </button>
                    </h5>
                </div>
            </div>
        </div>
        )
    }
}