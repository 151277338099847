import React from 'react';

export class ObjectListEstate extends React.Component {
    render(){
        const object = this.props.object;
        const type = this.props.type;
        const starred = this.props.starred;
        const hidden = this.props.hidden;

        const fadeOnLoad = (e) => {
            e.currentTarget.classList.add('fadeIn')
        } 

        let livingArea = object.details.livingArea ? <span><strong>{object.details.livingArea}</strong> <span className="text-muted">m²</span></span> : "";
        let rooms = object.details.rooms ? <span><strong>{object.details.rooms}</strong> <span className="text-muted">rok</span></span> : "";
        let price = object.details.price ? <span><strong>{object.details.price.toLocaleString('se')}</strong> <span className="text-muted">kr</span></span> : "";
        let exhibition = object.details.nextExhibition ? 
            (
                <span>
                    <span className="text-muted">Visning</span>
                    <br/>
                    <strong>{new Date(object.details.nextExhibition).toLocaleString('sv-SE', { timeZone: 'UTC', dateStyle: 'short' })}</strong>
                    {/* <br/>
                    <span className="text-muted"> kl. </span> 
                    <strong>{new Date(object.details.nextExhibition).toLocaleString('sv-SE', { timeZone: 'UTC', timeStyle: 'short' })}</strong> */}
                </span>
            ) 
            : "";
        
        return (
            <tr className={`animated fadeIn ${hidden ? "hide-object" : ""}`} key={object.uniqueId} style={{height: "81px"}}>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "150px"}}>
                    <a className="w-100" href={object.details.link} target="_blank" rel="noopener noreferrer">
                        <img src={object.details.image} alt={object.details.address} className="animated" onLoad={fadeOnLoad} style={{ width: "100px", maxHeight: "66px", objectFit: "cover" }}/>
                    </a>
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "400px"}}>
                    <strong>{object.details.address}</strong>
                    {object.details.city && <br/>} {object.details.city}
                    {object.details.area && <br/>} {object.details.area}
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "300px"}}>
                    {type} {object.details.type} {object.details.type && <br/>}
                    {object.details.running === "headStart" && <span><i className="fas fa-sign"></i> Förhandsvisning</span>}
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`}>
                    {livingArea} {livingArea && <br/>}
                    {rooms} {rooms && <br/>}
                    {price}
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`}>
                    {exhibition}
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "145px"}}>
                    <button className={`btn btn-link text-primary p-0 star-btn star-object-btn ${starred ? "obj-starred" : ""}`} onClick={this.props.updateFirstObject} data-uniqueid={object.uniqueId}
                    data-container="body" data-popover="popover" data-trigger="hover" data-placement="left" data-content={starred ? `Återställ objektets placering` : `Placera objektet först`}>
                        {starred ? <i className="fas fa-star text-warning"></i> : <i className="far fa-star"></i>}
                    </button>
                    <br/>
                    <button className="btn btn-link hide-object-btn text-primary p-0" onClick={this.props.updateHiddenObjects} data-uniqueid={object.uniqueId}
                    data-container="body" data-popover="popover" data-trigger="hover" data-placement="left" data-content={hidden ? `Visa objektet` : `Dölj objektet`}>
                        {hidden ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                    </button>
                </td>
            </tr>
        )
    }
}