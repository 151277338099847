import React from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import logo from '../img/Aura_Logo_Liggande.svg';

class Login extends React.Component {
    render() {
        let errorMessage = true;
        if (this.props.errorMessage) {
            errorMessage = (
                <div className="alert alert-danger w-25" style={{margin: '20px auto 0 auto'}} role="alert">
                    {this.props.errorMessage}
                </div>
            )
        }
        let supportBtn = (
            <button className="btn btn-info support-btn float-right ml-auto" type="button"
                data-container="body" data-popover="popover" data-trigger="focus" data-placement="bottom" data-html="true"
                title="Support" data-content="Är det något som inte fungerar,<br>eller har ni frågor om Aura?<br><i class='far fa-envelope text-muted mt-2 mr-1 support-mail-icon'></i> <a class='support-mail-link' href='mailto:aura@ntm.se'>aura@ntm.se</a>">
                <i className="fas fa-question-circle"></i>
            </button>
        );
        return (
            <div className="mt-5">
                {errorMessage}
                <div id="loginCard" className="col-xl-3 col-lg-4 col-md-6 col-sm-9 mt-3 mx-auto animated fadeIn fast">
                    <div className="text-center p-5 mb-4">
                        <img src={logo} alt="Aura"></img>
                    </div>
                    <form onSubmit={this.props.buttonClick}>
                        <div className="inputs p-3">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                                <input id="username" autoComplete="username" type="text" className="form-control" placeholder="Användarnamn" aria-label="Användarnamn" aria-describedby="basic-addon1" />
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-key"></i>
                                    </span>
                                </div>
                                <input id="password" autoComplete="current-password" type="password" className="form-control" placeholder="Lösenord" aria-label="Lösenord" aria-describedby="basic-addon1" />
                            </div>
                            <button className="btn btn-primary w-50 m-auto" href="#" onClick={this.props.buttonClick}>Logga in</button>
                            {supportBtn}
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Login;