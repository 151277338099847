import React from 'react';

export class SaveButton extends React.Component {
    render() {

        const buttonStyle = {
            bottom: 60,
            right: 50,
            width: 80,
            height: 80
        }
        let saveText = this.props.filterSaved ? <h2 className="m-0 animated jackInTheBox"><i className="fas fa-check"></i></h2> : <div style={{lineHeight: "16px"}}><h3 className="m-0"><i className="fas fa-save"></i></h3><span style={{fontSize: 16}}>Spara</span></div>;
        return (
            <button type="button" className="btn btn-success btn-lg position-absolute shadow rounded-circle animated fadeInUp fast" style={buttonStyle} onClick={this.props.postFilter}>
                {saveText}
            </button>
        )
    }
}