import React from 'react';

export class ObjectCardNews extends React.Component {
    render(){
        const object = this.props.object;
        const starred = this.props.starred;
        const hidden = this.props.hidden;

        const newsLink = {
            textDecoration: 'none'
        }

        let content = object.details.content ? <p className="card-text description-text mt-3">{object.details.content}</p> : "";
        let category = object.details.category ? <span className="card-subtitle description-text mt-3 text-muted">{object.details.category}</span> : "";
        let date = object.details.date ? <span className="card-subtitle description-text mt-3 text-muted">{object.details.date}</span> : "";

        return (
            <div className="
                    mb-3
                    col-sm-6 
                    col-md-6 
                    col-lg-4 
                    col-xl-3
                    animated fadeIn
                    card-row" key={object.uniqueId}>
            <div className={`card mt-3 mr-0 ml-0 shadow-sm h-100 ${hidden ? "hide-object" : ""}`}>
                <div className="card-body">
                    <a style={newsLink} href={object.details.link} target="_blank" rel="noopener noreferrer">
                        <h5 className="card-title">{object.details.title}</h5>
                    </a>
                    {content}
                    <p className="card-subtitle">
                        {category}
                        <br/>
                        {date}
                    </p>
                </div>
                <div className="card-footer bg-secondary">
                    <h5 className="text-white mb-0">
                        <button className={`btn btn-link text-white float-right p-0 ml-2 star-object-btn ${starred ? "obj-starred" : ""}`} onClick={this.props.updateFirstObject} data-uniqueid={object.uniqueId}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content={starred ? `Återställ objektets placering` : `Placera objektet först`}>
                            {starred ? <i className="fas fa-star text-warning"></i> : <i className="far fa-star"></i>}
                        </button>
                        <button className="btn btn-link hide-object-btn p-0 float-right text-white" onClick={this.props.updateHiddenObjects} data-uniqueid={object.uniqueId}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content={hidden ? `Visa objektet` : `Dölj objektet`}>
                            {hidden ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                        </button>
                    </h5>
                </div>
            </div>
        </div>
        )
    }
}