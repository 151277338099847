import React from 'react';
import {NavbarContainer} from './containers/NavbarContainer';
import {LoginContainer} from './containers/LoginContainer';
import {nadirAuthenticate} from './handlers/nadir';
import {nadirGetCustomers} from './handlers/nadir';
import {nadirGetCustomerById} from './handlers/nadir';
import {nadirTestToken} from './handlers/nadir';
import {nadirGetUserDetails} from './handlers/nadir';
import {nadirGetUsers} from './handlers/nadir';
import {AdminContainer} from './containers/AdminContainer';
import {BackgroundLoader} from './components/BackgroundLoader'
import {Error} from './components/Error'
import './App.css';
import './styles/aura.scss';
import 'animate.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { CampaignListContainer } from './containers/CampaignListContainer';
import jwt_decode from 'jwt-decode';
import $ from 'jquery';

const initialState = {
  customers: [],
  loggedIn: false,
  user: {},
  currentCustomerId: null,
  currentCustomer: null,
  currentView: 'login',
  admin: null,
  loading: false,
  users: null,
  errorMessage: null,
  error: {
    show: false,
    icon: null,
    message: null
  }
}

export class Aura extends React.Component {
  constructor(props){
    super(props);
    this.state = initialState;
  
    this.updateCustomers = this.updateCustomers.bind(this);
    this.updateUsers = this.updateUsers.bind(this);
    this.logIn = this.logIn.bind(this);
    this.logOut = this.logOut.bind(this);
    this.logInCallback = this.logInCallback.bind(this);
    this.setCurrentCustomer = this.setCurrentCustomer.bind(this);
    this.setCurrentView = this.setCurrentView.bind(this);
    this.loading = this.loading.bind(this);
    this.refreshCustomers = this.refreshCustomers.bind(this);
    this.refreshUsers = this.refreshUsers.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.setError = this.setError.bind(this);
  }

  componentDidMount(){
    $('[data-popover="popover"]').popover();
    // Här kontrollerar vi om det finns en token sedan tidigare
    const token = localStorage.getItem('token');
    if (token){
      // Token finns, testa den.
      nadirTestToken(token, (isValid) => {
        if (isValid){
          // Om token fortfarande är OK.
          const decodedToken = jwt_decode(token);
          const userName = decodedToken.username;
          nadirGetUserDetails(token, userName, (response) => {
            let user = response[0]
            let defaultView = user.role === 'admin' ? 'none' : 'campaignList';
            if (user.role === "admin") {
              nadirGetCustomers(localStorage.getItem('token'), this.updateCustomers);
            }
            this.setState({
              loggedIn: isValid,
              user: user,
              currentView: defaultView
            });
            if (user.products.hasOwnProperty('aura')) {
              this.setState({ currentCustomerId: user.products.aura.customerId })
              nadirGetCustomerById(localStorage.getItem('token'), this.state.currentCustomerId, (response) => {
                this.setState({ 
                  currentCustomer: response.customer,
                });
              });
            }
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    $('[data-popover="popover"]').popover();
    if (this.state.loggedIn){
      const token = localStorage.getItem('token');
      nadirTestToken(token, (isValid) => {
        if (!isValid) {
          let newState = JSON.parse(JSON.stringify(initialState));
          newState.errorMessage = "Du har blivit utloggad. Var god logga in igen."
          this.setState(newState);
        } else {
          localStorage.setItem('token', isValid);
        }
      });
    }
  }

  logIn(e) {
    e.preventDefault();
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;
    nadirAuthenticate(username, password, this.logInCallback);
  }

  logOut(e) {
    this.setState(initialState);
    localStorage.removeItem('token');
  }

  logInCallback(user) {
    if (user) {
      const token = localStorage.getItem('token');
      const decodedToken = jwt_decode(token);
      const userName = decodedToken.username;
      nadirGetUserDetails(token, userName, (response) => {
        let user = response[0]
        let defaultView = user.role === 'admin' ? 'none' : 'campaignList';
        if (user.role === "admin") {
          nadirGetCustomers(localStorage.getItem('token'), this.updateCustomers);
        }
        this.setState({
          loggedIn: true,
          user: user,
          currentView: defaultView
        });
        if (user.products.hasOwnProperty('aura')) {
          this.setState({ currentCustomerId: user.products.aura.customerId })
          nadirGetCustomerById(localStorage.getItem('token'), this.state.currentCustomerId, (response) => {
            this.setState({ 
              currentCustomer: response.customer,
            });
          });
        }
      });
    }
  }

  refreshCustomers(){
    nadirGetCustomers(localStorage.getItem('token'), this.updateCustomers);
  } 
  refreshUsers() {
    nadirGetUsers(localStorage.getItem('token'), this.updateUsers);
  }
  
  updateCustomers(customers) {
    customers.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
    this.setState({ customers: customers });
  }

  updateUsers(users) {
    let usersResponse;
    if (users.hasOwnProperty('users')){
      usersResponse = users.users;
    } else {
      usersResponse = users;
    }
    usersResponse.sort(function (a, b) { return (a.username > b.username) ? 1 : ((b.username > a.username) ? -1 : 0); });
    this.setState({ users: usersResponse });
  }

  setCurrentCustomer(customerId) {
    let currentCustomer;
    this.state.customers.forEach(customer=>{
      if (customer.customerId === customerId) {
        currentCustomer = customer;
      }
    });
    this.setState({
      currentCustomerId: customerId,
      currentCustomer: currentCustomer,
      currentView: 'campaignList'
    });
  }

  setCurrentView(view, subview=null){
    let newState = { currentView: view }
    if (subview){
      newState[view] = subview;
    }
    if (view === 'admin' && subview === 'users'){
      const token = localStorage.getItem('token');
      nadirGetUsers(token, users => {
        newState['users'] = users.users
        this.setState(newState);
      });
    } else {
      this.setState(newState);
    }
  }

  loading(bool){
    this.setState({loading: bool})
  }

  setError(err){
    this.setState({error: err})
  }

  getUsers(e){
    const token = localStorage.getItem('token');
    nadirGetUsers(token, users => {
      this.setState({
        users: users.users
      });
    });
  }

  render(){
    let campaignList = this.state.currentView === 'campaignList' ? true : false;
    let admin = this.state.currentView === 'admin' ? true : false;

    return (
      <div className="App bg-light">
        <NavbarContainer 
          customers={this.state.customers} 
          user={this.state.user} 
          loggedIn={this.state.loggedIn} 
          onClick={this.logOut} 
          updateCustomer={this.setCurrentCustomer} 
          setCurrentView={this.setCurrentView} 
          view={this.state.currentView}
          customerObject={this.state.currentCustomer}
          getUsers={this.getUsers}
        />
        {!this.state.loggedIn &&  <LoginContainer onClick={this.logIn} errorMessage={this.state.errorMessage}/>}
        { (campaignList && this.state.currentCustomer) && <CampaignListContainer 
                          current-customer={this.state.currentCustomerId} 
                          customer={this.state.currentCustomer}
                          loggedIn={this.state.loggedIn} 
                          userRole={this.state.user.role}
                          loading={this.loading}
                          setError={this.setError}
                          user={this.state.user}
                          />}
        { admin && <AdminContainer 
                    userRole={this.state.user.role} 
                    setView={this.setCurrentView}
                    route={this.state.admin}
                    customers={this.state.customers}
                    updateCustomers={this.updateCustomers}
                    refreshCustomers={this.refreshCustomers}
                    updateUsers={this.updateUsers}
                    refreshUsers={this.refreshUsers}
                    users={this.state.users}
                    setError={this.setError}
                    />}
        { this.state.loading && <BackgroundLoader show={this.state.loading} /> }
        { this.state.error.show && <Error error={this.state.error} /> }
      </div>
    )
  }
}

export default Aura;