import React from 'react';
import { SketchPicker } from 'react-color';

const cardListStyle = {
    overflowY: "hidden",
    overflowX: "hidden",
    height: "100%"
}


export class CustomerForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            displayColorPicker: false
        }
    }
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    
    render() {
        const inputGroupStyle = { width: 150 };
        const customer = this.props.customer;
        let color;
        if (customer.hasOwnProperty('color')) {
            color = customer.color;
        } else {
            color = 'rgba(0, 0, 0, 0.7)';
        }
        let saveButtonState = 'disabled';
        if (customer.name !== "" && customer.customerId !== "") {
            saveButtonState = '';
        }
        let owner;
        if (customer.hasOwnProperty('owner')){
            owner = customer.owner;
        } else {
            owner = '';
        }
        const pickerStyles = {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: color,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                position: 'absolute',
                right: 10
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
                left: '80%',
                top: 40
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            }
        }
        let office = customer.hasOwnProperty('office') ? customer.office : '';
        let objectLimitText;
        if (customer.hasOwnProperty('rules')) {
            if (customer.rules.hasOwnProperty('objectLimit')) {
                objectLimitText = customer.rules.objectLimit !== null ? "Max " + customer.rules.objectLimit + " objekt" : 'Alla objekt';
            }
            else {
                objectLimitText = 'Antal objekt';
            }
        }
        else {
            objectLimitText = 'Antal objekt';
        }
        return (
            <div className="bg-light p-4 col" style={cardListStyle}>
                <div className="input-group input-group-lg mb-2">
                    <div className="input-group-prepend" style={inputGroupStyle}>
                        <span className="input-group-text w-100" id="inputGroup-sizing-lg">Namn</span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-lg"
                        value={customer.name}
                        data-key="name"
                        onChange={this.props.updateCustomer} />

                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend" style={inputGroupStyle}>
                        <span className="input-group-text w-100" id="inputGroup-sizing-default">Kundnummer</span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        value={customer.customerId}
                        data-key="customerId"
                        onChange={this.props.updateCustomer} />
                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend" style={inputGroupStyle}>
                        <span className="input-group-text w-100" id="inputGroup-sizing-default">GAM-id</span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        value={customer.gamId}
                        data-key="gamId"
                        onChange={this.props.updateCustomer} />
                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend" style={inputGroupStyle}>
                        <span className="input-group-text w-100" id="inputGroup-sizing-default">Kontor</span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        value={office}
                        data-key="office"
                        onChange={this.props.updateCustomer} />
                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend" style={inputGroupStyle}>
                        <span className="input-group-text w-100" id="inputGroup-sizing-default">Fordringsägare</span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        value={owner}
                        data-key="owner"
                        onChange={this.props.updateCustomer} />
                </div>
                <div className="input-group mb-2">
                    <div className="input-group-prepend" style={inputGroupStyle}>
                        <span className="input-group-text w-100" id="inputGroup-sizing-default">Bild</span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        value={customer.logoUrl}
                        data-key="logoUrl"
                        onChange={this.props.updateCustomer} />
                </div>
                <div className="btn-group">
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: 150 }}>
                            {this.props.typeName}
                        </button>
                        <div className="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item" data-type="broker" data-form="customer" onClick={this.props.changeType}><i className="fas fa-home fa-fw"></i> Mäklare</button>
                            <button className="dropdown-item" data-type="auto" data-form="customer" onClick={this.props.changeType}><i className="fas fa-car fa-fw"></i> Bilhandlare</button>
                            <button className="dropdown-item" data-type="grocery" data-form="customer" onClick={this.props.changeType}><i className="fas fa-shopping-cart fa-fw"></i> Matbutik</button>
                            <button className="dropdown-item" data-type="news" data-form="customer" onClick={this.props.changeType}><i className="fas fa-bullhorn fa-fw"></i> Nyheter</button>
                            <button className="dropdown-item" data-type="event" data-form="customer" onClick={this.props.changeType}><i className="fas fa-calendar-check fa-fw"></i> Evenemang</button>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className="btn btn-primary text-white dropdown-toggle" type="button" id="dropdownMenuButton" disabled={!this.props.customer.type} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: 150 }}>
                            {objectLimitText}
                        </button>
                        <div className="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton">
                            {
                                this.props.customer.type === "news" &&
                                <button className="dropdown-item" data-ruletype="object-limit" data-limit="3" onClick={this.props.changeRules}>3 objekt</button>
                            }
                            <button className="dropdown-item" data-ruletype="object-limit" data-limit="5" onClick={this.props.changeRules}>5 objekt</button>
                            {
                                this.props.customer.type !== "news" &&
                                <>
                                    <button className="dropdown-item" data-ruletype="object-limit" data-limit="10" onClick={this.props.changeRules}>10 objekt</button>
                                    <button className="dropdown-item" data-ruletype="object-limit" data-limit="25" onClick={this.props.changeRules}>25 objekt</button>
                                    <button className="dropdown-item" data-ruletype="object-limit" data-limit="50" onClick={this.props.changeRules}>50 objekt</button>
                                    <button className="dropdown-item" data-ruletype="object-limit" data-limit="999" onClick={this.props.changeRules}>Alla objekt</button>
                                </>
                            }
                        </div>
                    </div>
                    {
                        this.props.customer.type === "grocery" &&
                        <>
                            <div className="dropdown ml-2">
                                <button className="btn btn-primary text-white dropdown-toggle" type="button" id="dropdownMenuButton" disabled={!this.props.customer.type} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: 150 }}>
                                    Erbjudandetyp
                                </button>
                                <ul className="dropdown-menu dropdown-hide p-0 shadow">
                                    <li className="mt-1 mb-1 dropdown-item pl-2">
                                        <div  className="custom-control custom-checkbox">
                                            <input 
                                                type="checkbox" 
                                                className="custom-control-input filter-checkbox" 
                                                data-ruletype="offer-types" 
                                                id="weekly-offer-input" 
                                                value="special"
                                                onChange={this.props.changeRules} 
                                                checked={this.props.customer.hasOwnProperty("rules") && this.props.customer.rules.hasOwnProperty("offerTypes") && this.props.customer.rules.offerTypes.includes("special")}
                                            />
                                            <label 
                                                className="custom-control-label" 
                                                htmlFor="weekly-offer-input" 
                                            >Veckans erbjudanden</label>
                                        </div>
                                    </li>
                                    <li className="mb-1 dropdown-item pl-2">
                                        <div  className="custom-control custom-checkbox">
                                            <input 
                                                type="checkbox" 
                                                className="custom-control-input filter-checkbox" 
                                                data-ruletype="offer-types" 
                                                id="member-offer-input" 
                                                value="member"
                                                onChange={this.props.changeRules} 
                                                checked={this.props.customer.hasOwnProperty("rules") && this.props.customer.rules.hasOwnProperty("offerTypes") && this.props.customer.rules.offerTypes.includes("member")}
                                            />
                                            <label 
                                                className="custom-control-label" 
                                                htmlFor="member-offer-input" 
                                            >Medlemserbjudanden</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="ml-2 mt-2">
                                <div  className="custom-control custom-checkbox">
                                    <input 
                                        type="checkbox" 
                                        className="custom-control-input filter-checkbox" 
                                        data-ruletype="flyer" 
                                        id="flyer-input" 
                                        onChange={this.props.changeRules} 
                                        checked={this.props.customer.hasOwnProperty("rules") && this.props.customer.rules.hasOwnProperty("showFlyer") && this.props.customer.rules.showFlyer}
                                    />
                                    <label 
                                        className="custom-control-label" 
                                        htmlFor="flyer-input" 
                                    >Visa reklamblad</label>
                                </div>
                            </div>
                        </>
                    }
                </div>

                {/* <div className="container p-2 border rounded bg-white w-25">
                    <img className="w-100" src={customer.logoUrl} alt="Logo"/>
                </div> */}
                <div className="card w-50 shadow" style={{margin: '20px auto'}}>
                    <div className="card-header bg-primary text-white font-weight-bold">
                        Färg <div
                            style={pickerStyles.swatch}
                            onClick={this.handleClick}
                        >
                            <div style={pickerStyles.color} />
                        </div>
                    </div>
                        {this.state.displayColorPicker ? <div className="popover" style={pickerStyles.popover}>
                            <div className="cover" style={pickerStyles.cover} onClick={this.handleClose} />
                            <SketchPicker color={customer.colorObj} onChange={this.props.colorChange} />
                        </div> : null}
                        <div style={{height: 400}}>
                            <div className="imgHolder h-100" style={{
                                background: "url('https://digital.ntm.eu/cdn/img/hus.jpg')",
                                backgroundSize: 'cover',
                                position: 'relative'
                            }}>
                                <img className="p-3" style={{position:'absolute', top: 0, left: 0, width: '30%'}} src={customer.logoUrl} alt="Logo" />
                                <div className="contentArea w-100 p-2" style={{
                                    color: '#FFF',
                                    background: color,
                                    position: 'absolute',
                                    bottom: 0
                                }}>
                                    <span className="font-weight-bold">Adressvägen 12</span><br />
                                    <span>Ortköping</span><br />
                                    <span>1 337 000 kr</span>
                                </div>
                            </div>
                        </div>
                    </div>

                <button
                    type="button"
                    className="btn btn-danger text-white delete"
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: 10
                    }}
                    data-toggle="modal"
                    data-target="#confirmDelete"
                    data-backdrop="true"
                    tabIndex='-1'
                >
                    <i className="fas fa-trash-alt"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-success"
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        right: 10
                    }}
                    onClick={this.props.saveCustomer}
                    disabled={saveButtonState}
                >
                    Spara
                </button>
            </div>
        )
    }
}
