import React from 'react';

export class StatusBar extends React.Component {
    render(){
        let objView = this.props.objectView;
        let filterSaved = this.props.filterSaved ? <span className="pl-3 float-left filter-saved-prompt animated fadeInLeft fast"><strong>Dina ändringar har sparats</strong><i className="ml-2 text-success fas fa-check"></i></span> : '';
        let filterText = this.props.numFilters > 0 ? (this.props.numFilters > 1) ? <i className="font-weight-light">({this.props.numFilters} filter applicerade)</i> : <i className="font-weight-light">({this.props.numFilters} filter applicerat)</i> : '';
        
        let sortingIcon = "";
        if (this.props.sorting.length !== 0) {
            if (this.props.sorting.includes("price-rising")) {
                sortingIcon = <i className="fas fa-sort-numeric-up-alt" data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content="Pris stigande"></i>;
            }
            else if (this.props.sorting.includes("price-falling")) {
                sortingIcon = <i className="fas fa-sort-numeric-down-alt" data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content="Pris fallande"></i>
            }
            else if (this.props.sorting.includes("random")) {
                sortingIcon = <i className="fas fa-random" data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-html="true" data-content="Objektens ordning slumpas<br>varje gång snurran visas"></i>
            }
        }

        return (
            <div className="row w-100 bg-white border-top status-bar">
                <p className="col-sm-12 col-md-9 col-lg-10 pl-2 pr-4 text-muted">
                    <span className="float-left pl-2">
                        <button className={`btn btn-link status-bar-btn p-0 mr-2 ${objView === "card" ? "text-info" : "text-muted"}`} onClick={this.props.changeObjectView} data-view="card"
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content="Rutnätsvy">
                            <i className="fas fa-th"></i>
                        </button>
                        <button className={`btn btn-link status-bar-btn p-0 ${objView === "list" ? "text-info" : "text-muted"}`} onClick={this.props.changeObjectView} data-view="list"
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content="Listvy">
                            <i className="fas fa-list"></i>
                        </button>
                        <span className="ml-3 mr-3 text-muted divider">|</span>
                        <button className="btn btn-link status-bar-btn p-0 mr-2 text-muted" data-showhide="hide-all" onClick={this.props.updateHiddenObjects}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content={`Dölj ${(this.props.objectCount - this.props.hiddenObjectsInView)} synliga objekt`}>
                            <i className="far fa-eye-slash"></i>
                        </button>
                        <button className="btn btn-link status-bar-btn p-0 text-muted" data-showhide="show-all" onClick={this.props.updateHiddenObjects}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content={`Visa ${this.props.hiddenObjectsInView} dolda objekt`}>
                            <i className="far fa-eye"></i>
                        </button>
                        <span className="ml-3 mr-3 text-muted divider">|</span>
                        <button className="btn btn-link status-bar-btn p-0 mr-2 text-muted" onClick={this.props.toggleSliderModal} data-toggle="modal" data-target="#exampleModal" style={{textDecoration: "none"}}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content="Förhandsgranska annons">
                            <i className="fas fa-images"></i>
                        </button>
                        {/* <a href={"https://rapport.ntm.se/" + this.props.lineitem} target="_blank" rel="noopener noreferrer" className="btn btn-link status-bar-btn p-0 text-muted" style={{textDecoration: "none"}}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content="Kampanjrapport">
                            <i className="fas fa-chart-line"></i>
                        </a> */}
                        {
                            this.props.userRole === "admin" &&
                            <a href={"https://admanager.google.com/22646727355#delivery/line_item/detail/line_item_id=" + this.props.lineitem} target="_blank" rel="noopener noreferrer" className="btn btn-link status-bar-btn p-0 text-muted" style={{textDecoration: "none"}}
                            data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content="Öppna kampanjen i GAM">
                                <i className="fas fa-ad"></i>
                            </a>
                        }
                    </span>
                    {filterSaved}
                    <span className="float-right">
                        <span className="mr-3 text-success">{sortingIcon}</span>
                        <span>Visar <strong>{(this.props.objectCount - this.props.hiddenObjectsInView)}</strong> av <strong>{this.props.allObjectsCount}</strong> objekt {filterText} </span>
                    </span>
                </p>
            </div>
        )
    }
}