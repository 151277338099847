import React from 'react';

export class CampaignList extends React.Component {
    render() {
        const cardListStyle = {
            overflowY: "hidden",
            overflowX: "hidden",
            height: "calc(100% - 4px)"
        }

        const tableDivStyle = {
            overflow: "scroll",
            overflowX: "hidden",
            marginLeft: "-15px",
            marginRight: "-15px"
        }

        const rowStyle = {
            height: 'calc(100% - 25px)'
        }

        let defaultMsg;

        const typeTranslation = {
            'broker': <i className="far fa-building mb-2"></i>,
            'auto': <i className="fas fa-car-side mb-2"></i>,
            'grocery': <i className="fas fa-shopping-cart mb-2"></i>,
            'news': <i className="fas fa-bullhorn mb-2"></i>,
            'event': <i className="fas fa-calendar-check mb-2"></i>
        }
        const campaignIcon = this.props.customerType ? typeTranslation[this.props.customerType] : '';

        if (this.props.objects.length === 0) {
            defaultMsg = (
                <h1 className="w-100 mt-5 align-middle text-center text-muted splash animated fadeIn">
                    {campaignIcon}<br />
                    Välj en kampanj<br />
                    i listan till vänster
                </h1>
            );
        } else {
            defaultMsg = '';
        }

        let objectView;
        if (this.props.objectView === "list") {
            objectView = (
                <div className="col-sm-12 col-md-9 col-lg-10 bg-light p-0 tablet-style" style={cardListStyle}>
                    <div className="container mw-100 object-container">
                        {this.props.filterBar}
                        <div className="tablet-height h-100" style={tableDivStyle}>
                            {defaultMsg}
                            <table className="tablet-table table table-hover mt-2 ml-2">
                                <tbody>
                                    {this.props.objects}
                                </tbody>
                            </table>
                            {this.props.saveButton}
                        </div>
                        {this.props.statusBar}
                    </div>
                </div>
            )
        }
        else {
            objectView = (
                <div className="col-sm-12 col-md-9 col-lg-10 bg-light p-0 tablet-style" style={cardListStyle}>
                    <div className="container mw-100 object-container">
                        {this.props.filterBar}
                        <div className="card-deck tablet-height h-100" style={{overflow: 'scroll', overflowX: 'hidden'}}>
                            {defaultMsg}
                            {this.props.objects}
                            {this.props.saveButton}
                        </div>
                        {this.props.statusBar}
                    </div>
                </div>
            )
        }

        let previousLineItemsHeader;
        if (this.props.previousLineitems.length > 0) {
            previousLineItemsHeader = (
                <button
                    className="list-group-item list-group-item-action bg-secondary text-white campaign-list-btn"
                    style={{ lineHeight: '16px' }}
                    data-category="previous"
                    data-toggle="collapse"
                    data-target="#previousLineitems"
                    aria-expanded="false"
                    aria-controls="previousLineitems"
                    onClick={this.props.clickHeader}
                >
                    <i className="fas fa-angle-right fa-fw mr-2"></i>
                    Tidigare kampanjer
                    <span className="badge badge-light text-secondary badge-pill float-right">{this.props.previousLineitems.length}</span>
                </button>
            )
        }
        let activeLineItemsHeader;
        if (this.props.lineitems.length > 0) {
            activeLineItemsHeader = (
                <button
                    className="list-group-item list-group-item-action bg-secondary text-white campaign-list-btn"
                    style={{lineHeight: '16px'}}
                    data-category="active"
                    data-toggle="collapse"
                    data-target="#activeLineitems"
                    aria-expanded="true"
                    aria-controls="activeLineitems"
                    onClick={this.props.clickHeader}
                >
                    <i className="fas fa-angle-down fa-fw mr-2"></i>
                    Aktiva kampanjer
                    <span className="badge badge-light text-secondary badge-pill float-right">{this.props.lineitems.length}</span>
                </button>
            );
        }
        let comingLineItemsHeader;
        if (this.props.comingLineitems.length > 0) {
            comingLineItemsHeader = (
                <button
                    className="list-group-item list-group-item-action bg-secondary text-white campaign-list-btn"
                    style={{ lineHeight: '16px' }}
                    data-category="coming"
                    data-toggle="collapse"
                    data-target="#comingLineitems"
                    aria-expanded="false"
                    aria-controls="comingLineitems"
                    onClick={this.props.clickHeader}
                >
                    <i className="fas fa-angle-right fa-fw mr-2"></i>
                    Kommande kampanjer
                    <span className="badge badge-light text-secondary badge-pill float-right">{this.props.comingLineitems.length}</span>
                </button>
            )
        }
        return (
        <div style={{height: "calc(100% - 30px)"}}>
            {this.props.sliderPreview}
            <div className="w-100 h-100 container mw-100 animated fast fadeIn tablet-responsive">
                <div className="row bg-white tablet-responsive" style={rowStyle}>
                    <div className="col-sm-12 col-md-3 col-lg-2 border-right pl-0 pr-0 h-100 campaigns">
                        <div className="list-group list-group-flush h-100 overflow-auto" id="campaignList">
                            {previousLineItemsHeader}
                            <div id="previousLineitems" className="collapse">
                                {this.props.previousLineitems}
                            </div>
                            {activeLineItemsHeader}
                            <div id="activeLineitems" className="collapse show">
                                {this.props.lineitems}
                            </div>
                            {comingLineItemsHeader}
                            <div id="comingLineitems" className="collapse">
                                {this.props.comingLineitems}
                            </div>
                        </div>
                    </div>
                    {objectView}
                </div>
            </div>
        </div>
        )
    }
}
