import React from 'react';

export class ObjectCardGrocery extends React.Component {
    render(){
        const object = this.props.object;
        const starred = this.props.starred;
        const hidden = this.props.hidden;

        const cardCoverStyle = { 
            background: 'rgba(0,0,0,0.3)', 
            opacity: 0, 
            height: 200,
            position: 'absolute'
        }

        const fadeOnLoad = (e) => {
            e.currentTarget.classList.add('fadeIn')
        } 
        
        let type = object.details.type ? <span className="text-muted">{object.details.type}</span> : "";

        return (
            <div className="
                    mb-3
                    col-sm-6 
                    col-md-6 
                    col-lg-4 
                    col-xl-3
                    animated fadeIn
                    card-row" key={object.uniqueId}>
            <div className={`card mt-3 mr-0 ml-0 shadow-sm h-100 ${hidden ? "hide-object" : ""}`}>
                <img src={object.details.image} alt={object.details.name} className="card-img-top img-fluid animated" onLoad={fadeOnLoad} style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                <a className="card-cover w-100" style={cardCoverStyle} href={object.details.link} target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-globe-europe mt-5"></i><br />
                    <p className="w-100 text-center text-white">Öppna objektsida</p>
                </a>
                <div className="card-img-overlay" style={{height: 200, pointerEvents: 'none'}}>
                        {object.details.offerType === "special" && <h5><span className="badge bg-orange text-white font-weight-normal float-right">Veckans erbjudande</span></h5>}
                        {object.details.offerType === "member" && <h5><span className="badge bg-red-orange text-white font-weight-normal float-right">Medlemserbjudande</span></h5>}
                </div>
                <div className="card-body">
                    <h5 className="card-title">{object.details.name}</h5>
                    <p className="card-subtitle description-text mt-3 mb-3">{object.details.description}</p>
                    <p className="card-text">
                        {type}
                    </p>                </div>
                <div className="card-footer bg-secondary">
                    <h5 className="text-white mb-0">
                        {object.details.price}
                        <button className={`btn btn-link text-white float-right p-0 ml-2 star-object-btn ${starred ? "obj-starred" : ""}`} onClick={this.props.updateFirstObject} data-uniqueid={object.uniqueId}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content={starred ? `Återställ objektets placering` : `Placera objektet först`}>
                            {starred ? <i className="fas fa-star text-warning"></i> : <i className="far fa-star"></i>}
                        </button>
                        <button className="btn btn-link hide-object-btn p-0 float-right text-white" onClick={this.props.updateHiddenObjects} data-uniqueid={object.uniqueId}
                        data-container="body" data-popover="popover" data-trigger="hover" data-placement="top" data-content={hidden ? `Visa objektet` : `Dölj objektet`}>
                            {hidden ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                        </button>
                    </h5>
                </div>
            </div>
        </div>
        )
    }
}