import React from "react";

export class CreateUserModal extends React.Component {
    render(){
        return (
            <div className="modal fade" id="createUserModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Skapa användare</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body bg-light">
                        {/* Användarnamn */}
                        <div className="input-group input-group mb-2">
                        <div className="input-group-prepend" style={{ width: 150 }}>
                            <span className="input-group-text w-100">Användarnamn</span>
                        </div>
                        <input
                            id="createUserUsername"
                            type="text"
                            className="form-control"
                            data-key="username"
                            />
                        </div>

                        {/* Lösenord */}
                        <div className="input-group input-group mb-2">
                        <div className="input-group-prepend" style={{ width: 150 }}>
                            <span className="input-group-text w-100">Lösenord</span>
                        </div>
                        <input
                            id="createUserPassword"
                            type="password"
                            className="form-control"
                            data-key="password"
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            id="saveUserButton"
                            type="button" 
                            className="btn btn-success"
                            onClick={this.props.createUser}
                            style={{width: 200}}
                            >Skapa användare</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Avbryt</button>
                    </div>
                    </div>
                </div>
                </div>
        )
    }
}