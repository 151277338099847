export const gamGetLineItemsByCustomerId = (gamId) => {
    return new Promise((resolve, reject) => {
        // fetch(`http://localhost:5000/aura/getOrdersByCustomerId/${customerId}`)
        fetch(`https://rapportverktyg-backend.azurewebsites.net/aura/getOrdersByCustomerId/${gamId}`)
            .then(response => response.json())
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.warn(error);
                reject(error);
            })
    })
}

export const gamGetCreativesByLineItemId = (lineItem) => {
    return new Promise((resolve, reject) => {
        // fetch(`http://localhost:5000/aura/getOrdersByCustomerId/${customerId}`)
        fetch(`https://rapportverktyg-backend.azurewebsites.net/getCreativesByLineItemId/${lineItem}`)
            .then(response => response.json())
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.warn(error);
                reject(error);
            })
    })
}

export const dateFormatter = date => {
    if (date.year && date.month && date.day) return new Date(date.year, date.month - 1, date.day, 12).toISOString().substring(0, 10);
    else return "-";
};