import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

export class DateRangePicker extends React.Component {
    constructor(props) {
        super(props);

        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dates.from !== prevProps.dates.from && this.props.dates.to !== prevProps.dates.to) {
            this.setState(this.getInitialState());
        }
        if (prevState.from !== this.state.from || prevState.to !== this.state.to) {
            this.props.setDateFilter({ from: this.state.from, to: this.state.to });
        }
    }

    getInitialState() {
        return {
            from: this.props.dates.from || null,
            to: this.props.dates.to || null,
            enteredTo: this.props.dates.to || null
        };
    }

    isSelectingFirstDay = (from, to, day) => {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    };

    handleDayClick(day) {
        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
            this.setState({ from: null, to: null, enteredTo: null });
            return;
        }
        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });
        } else {
            this.setState({
                to: day,
                enteredTo: day,
            });
        }
    };

    handleDayMouseEnter(day) {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day,
            });
        };
    };

    render() {
        const { from, enteredTo } = this.state;
        const modifiers = { start: from, end: enteredTo };
        const selectedDays = [from, { from, to: enteredTo }];

        return (
            <>
                <DayPicker 
                    className="Selectable"
                    numberOfMonths={2}
                    firstDayOfWeek={1}
                    fixedWeeks
                    months={[
                        "Januari",
                        "Februari",
                        "Mars",
                        "April",
                        "Maj",
                        "Juni",
                        "Juli",
                        "Augusti",
                        "September",
                        "Oktober",
                        "November",
                        "December",
                    ]}
                    weekdaysLong={[
                        "Söndag",
                        "Måndag",
                        "Tisdag",
                        "Onsdag",
                        "Torsdag",
                        "Fredag",
                        "Lördag",
                    ]}
                    weekdaysShort={["Sö", "Må", "Ti", "On", "To", "Fr", "Lö"]}
                    selectedDays={selectedDays}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    onDayMouseEnter={this.handleDayMouseEnter}
                />
                <style>
                    {`
                        .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                            background-color: #f0f8ff !important;
                            color: #4a90e2;
                        }
                        .Selectable .DayPicker-Day {
                            border-radius: 0 !important;
                        }
                        .Selectable .DayPicker-Day--start {
                            border-top-left-radius: 50% !important;
                            border-bottom-left-radius: 50% !important;
                        }
                        .Selectable .DayPicker-Day--end {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                        }
                        .DayPicker-Months {
                            flex-direction: row;
                            flex-wrap: nowrap;
                        }
                    `}
                </style>
            </>
        )
    } 
}