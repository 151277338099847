import React from 'react';
import logo from '../img/Aura_Logo_Liggande_light.svg';


export class Navbar extends React.Component {
    render(){
        let customerDropdown;
        let adminDropdown;

        if (this.props.user.role === 'admin') {
            let dropdownLabel = <span><i className="fas fa-id-card-alt mr-1"></i> Kunder</span>
            const icons = {
                'broker': <i className="fas fa-home mr-1"></i>,
                'auto': <i className="fas fa-car mr-1"></i>,
                'grocery': <i className="fas fa-shopping-cart mr-1"></i>,
                'news': <i className="fas fa-bullhorn mr-1"></i>,
                'event': <i className="fas fa-calendar-check mr-1"></i>
            }
            if (this.props.customerObject !== null){
                dropdownLabel = <span>{icons[this.props.customerObject.type]} {this.props.customerObject.name}</span>
            }
            customerDropdown = (
                <li className="dropdown animated fadeIn dropdown-keep-open">
                    <span className="nav-link dropdown-toggle" 
                        href="#" 
                        id="dropdownMenuButton" 
                        data-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false"
                        style={{cursor: 'pointer'}}
                    >
                        <span className="mr-1"> {dropdownLabel}</span>
                    </span>
                    <div className="dropdown-menu shadow" aria-labelledby="dropdownMenuButton" style={{maxHeight: 650, overflowY: "scroll"}}>
                        <input 
                            type="search" 
                            id="customerFilter" 
                            placeholder="Filtrera..." 
                            className="form-control m-2 bg-light" 
                            style={{width: "calc(100% - 15px)"}}
                            onChange={this.props.textFilterChange}
                            autoComplete="off"
                        ></input>
                        <div className="list-group">
                            <div className="list-group-item" style={{display: "inherit", border: "none", padding: "0.5rem"}}>
                                <div className="custom-control custom-radio radio-inline">
                                    <input type="radio" className="custom-control-input" data-customertype="" name="typeRadioButton" id="type-all" value="all" defaultChecked onChange={this.props.typeFilterChange}/>
                                    <label className="custom-control-label" htmlFor="type-all" style={{fontWeight: "bold"}}>Alla</label>
                                </div>
                                <div className="custom-control custom-radio radio-inline ml-3">
                                    <input type="radio" className="custom-control-input" data-customertype="broker" name="typeRadioButton" id="type-broker" value="broker" onChange={this.props.typeFilterChange}/>
                                    <label className="custom-control-label" htmlFor="type-broker" style={{fontWeight: "bold"}}>Bostad</label>
                                </div>
                                <div className="custom-control custom-radio radio-inline ml-3">
                                    <input type="radio" className="custom-control-input" data-customertype="auto" name="typeRadioButton" id="type-auto" value="auto" onChange={this.props.typeFilterChange}/>
                                    <label className="custom-control-label" htmlFor="type-auto" style={{fontWeight: "bold"}}>Motor</label>
                                </div>
                                <div className="custom-control custom-radio radio-inline ml-3">
                                    <input type="radio" className="custom-control-input" data-customertype="grocery" name="typeRadioButton" id="type-grocery" value="grocery" onChange={this.props.typeFilterChange}/>
                                    <label className="custom-control-label" htmlFor="type-grocery" style={{fontWeight: "bold"}}>Livs</label>
                                </div>
                                <div className="custom-control custom-radio radio-inline ml-3">
                                    <input type="radio" className="custom-control-input" data-customertype="news" name="typeRadioButton" id="type-news" value="news" onChange={this.props.typeFilterChange}/>
                                    <label className="custom-control-label" htmlFor="type-news" style={{fontWeight: "bold"}}>Nyheter</label>
                                </div>
                                <div className="custom-control custom-radio radio-inline ml-3">
                                    <input type="radio" className="custom-control-input" data-customertype="event" name="typeRadioButton" id="type-event" value="event" onChange={this.props.typeFilterChange}/>
                                    <label className="custom-control-label" htmlFor="type-event" style={{fontWeight: "bold"}}>Evenemang</label>
                                </div>
                            </div>
                        </div>
                        <table className="table table-hover table-sm table-borderless customer-table" style={{width: 650, fontSize: 14}}>
                            <tbody>
                                {this.props.customers}
                            </tbody>
                        </table>
                    </div>
                </li>
            );

            adminDropdown = (
                <li className="dropdown animated fadeIn">
                    <span className="nav-link dropdown-toggle" href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{cursor: 'pointer'}}>
                        <i className="fas fa-user-cog mr-1"></i><span className="mr-1"> Admin</span>
                    </span>
                    <div className="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton" style={{textTransform: 'none'}}>
                        <button className="dropdown-item" data-admin="users" onClick={this.props.setView} style={{padding: "0.25rem 1.5rem 0.25rem 0.5rem"}}><i className="fas fa-user fa-fw" style={{width: 40}} ></i> Hantera användare</button>
                        <button className="dropdown-item" data-admin="customers" onClick={this.props.setView} style={{padding: "0.25rem 1.5rem 0.25rem 0.5rem"}}><i className="fas fa-home fa-fw" style={{width: 40}} ></i> Hantera kunder</button>
                        {/* <button className="dropdown-item" data-admin="customer-activity" onClick={this.props.setView} style={{padding: "0.25rem 1.5rem 0.25rem 0.5rem"}}><i className="fas fa-clipboard-check fa-fw" style={{width: 40}} ></i> Kundaktivitet</button> */}
                    </div>
                </li>
            );
        } else {
            customerDropdown = '';
            adminDropdown = '';
        }
        let logoutBtn = <button className="btn btn-primary w-10 float-right animated fadeIn" href="#" onClick={this.props.buttonClick}>Logga ut</button>;
        let supportBtn = (
            <button className="btn btn-info support-btn float-right animated fadeIn ml-auto mr-2" type="button"
                data-container="body" data-popover="popover" data-trigger="focus" data-placement="bottom" data-html="true"
                data-title="Support" data-content="Är det något som inte fungerar,<br>eller har ni frågor om Aura?<br><i class='far fa-envelope text-muted mt-2 mr-1 support-mail-icon'></i> <a class='support-mail-link' href='mailto:aura@ntm.se'>aura@ntm.se</a>">
                <i className="fas fa-question-circle"></i>
            </button>
        );

        return (
            <nav className={`navbar ${this.props.user.role !== "admin" ? "navbar-expand" : "navbar-expand-md"} navbar-dark bg-dark shadow-sm animated fadeInDown faster`} style={{zIndex: 10}}>
                {
                    this.props.loggedIn &&
                    <>
                        <img src={logo} alt="Aura" className="animated slow fadeIn mr-3" style={{height: 35}}></img>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navContent">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navContent">
                            <ul className="navbar-nav mr-auto">
                                {customerDropdown}
                                {adminDropdown}
                            </ul>
                            <div className={`${this.props.user.role !== "admin" ? "" : "navbar-btn"}`}>
                                {logoutBtn}
                                {supportBtn}
                            </div>
                        </div>
                    </>
                }
            </nav>
            )
    }
}