import React from 'react';
import {CustomerForm} from './CustomerForm';
import {UserForm} from './UserForm';
import {CreateUserModal} from './CreateUserModal';


const containerStyle = {
    height: 'calc(100% - 60px)'
}
const unixDate = + new Date();

export class Admin extends React.Component {
    render() {
        const inputClick = (e) => {
            e.currentTarget.readOnly = false;
        }
        const inputBlur = (e) => {
            e.currentTarget.readOnly = true;
        }
        const typeTranslation = {
            'broker': <span><i className="fas fa-home"></i> Mäklare</span>,
            'auto': <span><i className="fas fa-car"></i> Bilhandlare</span>,
            'grocery': <span><i className="fas fa-shopping-cart"></i> Matbutik</span>,
            'news': <span><i className="fas fa-bullhorn"></i> Nyheter</span>,
            'event': <span><i className="fas fa-calendar-check"></i> Evenemang</span>,
            'user': <span><i className="fas fa-user"></i> Användare</span>,
            'restricted': <span><i className="fas fa-user-lock"></i> Begränsad</span>,
            'admin': <span><i className="fas fa-user-shield"></i> Admin</span>
        }
        const customerTypeName = this.props.customer ? typeTranslation[this.props.customer.type] : '';
        const userTypeName = this.props.user ? typeTranslation[this.props.user.role] : '';
        const textFilter = (
            <div className="form-group m-0">
                <input readOnly type="search" autoComplete="off" className="form-control rounded-0 bg-light border-0 p-2" id="filter" placeholder="filtrera..." onChange={this.props.filterItems} onClick={inputClick} onBlur={inputBlur} name={unixDate} />
            </div>
        )
        const activityToggle = (
            <div className="list-group-item line-item" style={{display: "inherit"}}>
                <div className="custom-control custom-radio">
                    <input type="radio" className="custom-control-input" data-filtertype="activity" name="activityRadioButton" id="activity-all" value="all" defaultChecked onChange={this.props.onChange}/>
                    <label className="custom-control-label" htmlFor="activity-all" style={{fontWeight: "bold"}}>ALLA KUNDER</label>
                </div>
                <div className="custom-control custom-radio ml-3">
                    <input type="radio" className="custom-control-input" data-filtertype="activity" name="activityRadioButton" id="activity-active" value="active" onChange={this.props.onChange}/>
                    <label className="custom-control-label" htmlFor="activity-active" style={{fontWeight: "bold"}}>AKTIVA KUNDER</label>
                </div>
                <div className="custom-control custom-radio ml-3">
                    <input type="radio" className="custom-control-input" data-filtertype="activity" name="activityRadioButton" id="activity-inactive" value="inactive" onChange={this.props.onChange}/>
                    <label className="custom-control-label" htmlFor="activity-inactive" style={{fontWeight: "bold"}}>INAKTIVA KUNDER</label>
                </div>
            </div>
        )
        return (
            <div className="h-100">
                <div className="w-100 container mw-100 animated fast fadeIn" style={containerStyle}>
                    <div className="row bg-white h-100">
                        <div className={`col-sm border-right pl-0 pr-0 h-100 ${this.props.route === "customer-activity" ? "col-md" : "col-md-3"}`}>
                            <div className="list-group list-group-flush h-100 overflow-auto">
                                {this.props.route !== "customer-activity" && textFilter}
                                {this.props.route === "customer-activity" && activityToggle}
                                {this.props.list}
                            </div>
                        </div>
                        {this.props.customer && <CustomerForm 
                                                    customer={this.props.customer} 
                                                    typeName={customerTypeName} 
                                                    filterItems={this.props.filterItems}
                                                    changeType={this.props.changeType}
                                                    changeRules={this.props.changeRules}
                                                    saveCustomer={this.props.saveCustomer}
                                                    updateCustomer={this.props.updateCustomer}
                                                    colorChange={this.props.colorChange}
                                                    ></CustomerForm>}
                        {this.props.user && <UserForm
                                                    user={this.props.user}
                                                    typeName={userTypeName}
                                                    filterItems={this.props.filterItems}
                                                    changeType={this.props.changeType}
                                                    saveCustomer={this.props.saveCustomer}
                                                    updateUser={this.props.updateUser}
                                                    users={this.props.users}
                                                    searchFilterResults={this.props.searchFilterResults}
                                                    searchCustomers={this.props.searchCustomers}
                                                    filterList={this.props.filterList}
                                                    clearSearch={this.props.clearSearch}
                                                    customers={this.props.customers}
                                                    putUser={this.props.putUser}
                                                ></UserForm>}
                    </div>
                </div>
                <div className="modal fade" id="confirmDelete" tabIndex="-1" role="dialog" aria-labelledby="confirmDeleteLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow-lg">
                            <div className="modal-header">
                                <h5 className="modal-title" id="confirmDeleteLabel">Bekräfta borttagning</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <span>Är du säker på att du vill ta bort kunden?</span>
                            </div>
                            <div className="modal-footer">
                                <button 
                                    type="button" 
                                    className="btn btn-danger" 
                                    onClick={this.props.deleteCustomer}
                                    tabIndex='-1'
                                >
                                    Helt säker
                                </button>
                                <button 
                                    type="button" 
                                    className="btn btn-secondary" 
                                    data-dismiss="modal"
                                    tabIndex='-1'
                                >
                                    Avbryt
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateUserModal
                    createUser={this.props.createUser} 
                />
            </div>
        )
    }
}