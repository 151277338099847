/**
 * Autentiserar mot Nadir
 * 
 * @param {string} username Användarnamn i Nadir
 * @param {string} password Lösenord i Nadir
 * @param {function} callback Callback-funktion att köra efter autentisering
 */
export const nadirAuthenticate = (username, password, callback)=>{
    const url = 'https://nadir.ntm.digital/auth';
    const postBody = {
        username: username,
        password: password
    };
    fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postBody)
        })
        .then(response => response.json())
        .then(response => {
            if (response.hasOwnProperty('token'))
            {
                localStorage.setItem('token', response.token);
                callback(response);
            } else {
                callback(false);
            }
        });
}

/**
 * 
 * @param {string} token En token att testa
 * @param {function} callback Callback-funktion att köra efter test
 * 
 * Returnerar `true` om token är giltig. Annars `false`
 */
export const nadirTestToken = (token, callback) => {
    fetch('https://nadir.ntm.digital/reauth', {
        headers: { 'Authorization': 'JWT ' + token }
    })
    .then(response => {
        if (response.status !== 200){
            callback(false);
            return false;
        } else {
            return response.json();
        }
    })
    .then(response => {
        callback(response.token);
    });
}

/**
 * 
 * @param {string} token En Nadir-token
 * @param {string} username Användarnamn att hämta information om
 * @param {function} callback Callback-funktion att köra när resultatet har hämtats.
 * 
 * Returnerar ett `object` med användarinformation
 */
export const nadirGetUserDetails = (token, username, callback) => {
    const url = 'https://nadir.ntm.digital/users/by-username';
    const postBody = { username: username };
    fetch(url, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + token
        },
        body: JSON.stringify(postBody)
    })
    .then(response => response.json())
    .then(response => {
        callback(response);
    });
}

export const nadirPutUser = (token, userId, data, callback) => {
    const url = 'https://nadir.ntm.digital/users/' + userId;
    fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + token
        },
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(response => {
        callback(response);
    });
}

export const nadirGetCustomers = (token, callback) => {
    fetch('https://nadir.ntm.digital/aura/customers', {
    // fetch('http://localhost:7777/aura/customers', {
        headers: { 'Authorization': 'JWT ' + token }
    })
        .then(response => response.json())
        .then(response => {
            callback(response.customers);
        });
}

export const nadirGetCustomerById = (token, customerId, callback) => {
    fetch('https://nadir.ntm.digital/aura/customers/' + customerId, {
    // fetch('http://localhost:7777/aura/customers/' + customerId, {
        headers: { 'Authorization': 'JWT ' + token }
    })
        .then(response => response.json())
        .then(response => {
            callback(response);
        });
}

export const nadirGetLineItemsFromCustomer = (token, customerId, callback) => {
    fetch('https://nadir.ntm.digital/quasar/adnuntius/running?company_id=' + customerId, {
        headers: { 'Authorization': 'JWT ' + token }
    })
        .then(response => response.json())
        .then(response => {
            callback(response.lineitems);
        });
}

export const nadirGetComingLineItemsFromCustomer = (token, customerId, callback) => {
    fetch('https://nadir.ntm.digital/quasar/adnuntius/upcoming?company_id=' + customerId, {
        headers: { 'Authorization': 'JWT ' + token }
    })
        .then(response => response.json())
        .then(response => {
            callback(response.lineitems);
        });
}

export const nadirGetObjectsFromCustomer = (token, customerId, callback) => {
    fetch('https://nadir.ntm.digital/aura/objects/' + customerId, {
    // fetch('http://localhost:7777/aura/objects/' + customerId, {
        headers: { 'Authorization': 'JWT ' + token }
    })
        .then(response => response.json())
        .then(response => {
            callback(response);
        });
}

export const nadirPostCampaign = (token, lineitem, filter, callback) => {
    const url = 'https://nadir.ntm.digital/aura/campaigns/' + lineitem;
    // const url = 'http://localhost:7777/aura/campaigns/' + lineitem;
    const postBody = {
        "lineitemId": lineitem,
        "customerId": filter["customerId"],
        "types": filter["types"],
        "areas": filter["areas"],
        "cities": filter["cities"],
        "running": filter["running"],
        "brands": filter["brands"],
        "models": filter["models"],
        "status": filter["status"],
        "fuels": filter["fuels"],
        "affiliates": filter["affiliates"],
        "prices": filter["prices"],
        "years": filter["years"],
        "mileages": filter["mileages"],
        "numObjects": filter["numObjects"],
        "offerTypes": filter["offerTypes"],
        "dates": filter["dates"],
        "hiddenObjects": filter["hiddenObjects"],
        "firstObjectId": filter["firstObjectId"],
        "sorting": filter["sorting"],
        "history": filter["history"]
    };
    fetch(url, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + token
        },
        body: JSON.stringify(postBody)
    })
    .then(response => response.json())
    .then(response => {
        callback(response);
    });
}

export const nadirGetCampaign = (token, lineitem, callback) => {
    fetch('https://nadir.ntm.digital/aura/campaigns/' + lineitem, {
    // fetch('http://localhost:7777/aura/campaigns/' + lineitem, {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + token 
        }
    })
    .then(response => response.json())
    .then(response => {
        callback(response);
    });
}

export const nadirGetObjectsFromLineItem = (token, customerId, lineitem, callback) => {
    fetch('https://nadir.ntm.digital/aura/objects/' + customerId + '/?lineItemId=' + lineitem, {
        // headers: { 'Authorization': 'JWT ' + token }
    })
    .then(response => response.json())
    .then(response => {
        callback(response);
    });
}

export const nadirPostCustomer = (token, customer, callback) => {
    const url = 'https://nadir.ntm.digital/aura/customers/' + customer.customerId;
    // const url = 'http://localhost:7777/aura/customers/' + customer.customerId;
    const defaultColor = 'rgba(0,0,0,0.7)';
    const defaultColorObj = {
        r: 0,
        g: 0,
        b: 0,
        a: 0.7
    };
    const defaultOffice = '';
    const defaultRules = {};
    const postBody = {
        "name": customer.name,
        "customerId": customer.customerId,
        "gamId": customer.gamId,
        "logoUrl": customer.logoUrl,
        "type": customer.type,
        "office": customer.hasOwnProperty('office') ? customer.office : defaultOffice,
        "color": customer.hasOwnProperty('color') ? customer.color : defaultColor,
        "colorObj": customer.hasOwnProperty('colorObj') ? customer.colorObj : defaultColorObj,
        "owner": customer.owner,
        "rules": customer.hasOwnProperty('rules') ? customer.rules : defaultRules
    };
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + token
        },
        body: JSON.stringify(postBody)
    })
        .then(response => response.json())
        .then(response => {
            callback(response);
        });
}

export const nadirDeleteCustomer = (token, customer, callback) => {
    const url = 'https://nadir.ntm.digital/aura/customers/' + customer.customerId;
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + token
        }
    })
        .then(response => response.json())
        .then(response => {
            callback(response);
        });
}

export const nadirGetPreview = (token, lineitem, callback) => {
    fetch('https://nadir.ntm.digital/aura/preview/' + lineitem + '?rand=' + Date.now(), {
        headers: { 'Authorization': 'JWT ' + token }
    })
    .then(response => response.json())
    .then(response => {
        callback(response);
    });
}

export const nadirGetUsers = (token, callback) => {
    fetch('https://nadir.ntm.digital/users', {
        headers: { 'Authorization': 'JWT ' + token }
    })
        .then(response => response.json())
        .then(response => {
            callback(response);
        });
}
/**
 * 
 * @param {string} token Nadir-token
 * @param {string} username Användarnamn på användaren som ska skapas
 * @param {string} password Lösenord till användaren som ska skapas
 * @param {function} callback Callback att köra när Nadir svarat
 */
export const nadirPostUser = (token, username, password, callback) => {
    const url = 'https://nadir.ntm.digital/users';
    fetch(url, {
        method: 'POST',
        headers: { 'Authorization': 'JWT ' + token },
        body: JSON.stringify({
            username: username,
            password: password
        })
    })
    .then(response => response.json())
    .then(response => {
        callback(response);
    });
}