import React from "react";

const submenuStyle = {
    minWidth: '70%'    
}

export class FilterMenuRangeItem extends React.Component {
    render() {
        let badge = <span className="ml-1 mr-1 mt-1 badge badge-light bg-primary text-white float-right">{this.props.filter.from} - {this.props.filter.to}</span>
        return (
            <li className="dropdown-submenu">
                <button className="dropdown-item dropdown-toggle dropdown-menu-button">
                    <i className={`mr-2 fa-fw ${this.props.icon}`}></i>
                    <span>{this.props.label} </span>
                    {(this.props.filter.from !== null || this.props.filter.to !== null) && badge}
                </button>
                <ul className={`dropdown-menu dropdown-hide p-0 shadow ${this.props.filterFrom.length > 5 ? "scrollable" : ""}`} style={submenuStyle}>
                    <li className="p-2 dropdown-header text-white bg-secondary">Från</li>
                    {this.props.filterFrom}
                    <li className="p-2 dropdown-header text-white bg-secondary">Till</li>
                    {this.props.filterTo}
                </ul>
            </li>
        );
    }
}