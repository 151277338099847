import React from 'react';
import { dateFormatter } from '../../handlers/googleAdManager';

export class CampaignInfo extends React.Component {
    render(){
        const liStyle = {
            listStyleType: "none",
            fontSize: "80%"
        }
        const li = this.props.lineitem;
        const filter = this.props.filter;

        const startDate = dateFormatter(li.startDate);
        const endDate = dateFormatter(li.endDate);

        let sites = [];
        if (li.placements) {
            sites = [...new Set(li.placements.map(p => p.split(" ")[0]))].join(", ");
        }

        const cityFilter = (
            <tr className="text-left">
                <th scope="row">Stad</th>
                <td>{filter.city.join(", ")}</td>
            </tr>
        );
        const areaFilter = (
            <tr className="text-left">
                <th scope="row">Område</th>
                <td>{filter.area.join(", ")}</td>
            </tr>
        );
        const typeFilter = (
            <tr className="text-left">
                <th scope="row">Typ</th>
                <td>{filter.type.join(", ")}</td>
            </tr>
        );
        const brandFilter = (
            <tr className="text-left">
                <th scope="row">Märke</th>
                <td>{filter.brand.join(", ")}</td>
            </tr>
        );
        const modelFilter = (
            <tr className="text-left">
                <th scope="row">Modell</th>
                <td>{filter.model.join(", ")}</td>
            </tr>
        );
        const fuelFilter = (
            <tr className="text-left">
                <th scope="row">Drivmedel</th>
                <td>{filter.fuel.join(", ")}</td>
            </tr>
        );
        const affiliateFilter = (
            <tr className="text-left">
                <th scope="row">Filial</th>
                <td>{filter.affiliate.join(", ")}</td>
            </tr>
        );
        const priceFilter = (
            <tr className="text-left">
                <th scope="row">Pris</th>
                <td>{filter.price.from} - {filter.price.to}</td>
            </tr>
        );
        const yearFilter = (
            <tr className="text-left">
                <th scope="row">Årsmodell</th>
                <td>{filter.year.from} - {filter.year.to}</td>
            </tr>
        );
        const mileageFilter = (
            <tr className="text-left">
                <th scope="row">Miltal</th>
                <td>{filter.mileage.from} {filter.mileage.from !== null && "mil"} - {filter.mileage.to} {filter.mileage.to !== null && "mil"}</td>
            </tr>
        );
        const hiddenObjFilter = (
            <tr className="text-left">
                <th scope="row">Dolda objekt</th>
                <td>{filter.hiddenObjects.length}</td>
            </tr>
        );
        
        let runningType = "Alla"
        if (filter.running.length !== 0) {
            if (filter.running[0] === "headStart") {
                runningType = "Endast förhandsvisning"
            }
            else if (filter.running[0] === "normal") {
                runningType = "Ej förhandsvisning"
            }
        }
        const runningFilter = (
            <tr className="text-left">
                <th scope="row">Förhandsvisning</th>
                <td>{runningType}</td>
            </tr>
        );

        let numObjectsFilter = (
            <tr className="text-left">
                <th scope="row">Antal objekt per produkttyp</th>
                <td>{filter.numObjects + " objekt"}</td>
            </tr>
        );

        let offerTypeTranslate = {
            "member": "Medlemserbjudanden",
            "special": "Veckans erbjudanden"
        }
        const offerTypes = filter.offerType.map((type) => {
            return offerTypeTranslate[type];
        });
        const offerTypeFilter = (
            <tr className="text-left">
                <th scope="row">Erbjudandetyp</th>
                <td>{offerTypes.join(", ")}</td>
            </tr>
        );

        const datesFilter = (
            <tr className="text-left">
                <th scope="row">Datumintervall</th>
                <td>{filter.dates.from !== null && filter.dates.from.toISOString().slice(0, 10)} - {filter.dates.to !== null && filter.dates.to.toISOString().slice(0, 10)}</td>
            </tr>
        );

        let statusType = "Alla bilar"
        if (filter.status.length !== 0) {
            if (filter.status[0] === "used") {
                statusType = "Begagnade bilar"
            }
            else if (filter.status[0] === "new") {
                statusType = "Nya bilar"
            }
        }
        const statusFilter = (
            <tr className="text-left">
                <th scope="row">Status</th>
                <td>{statusType}</td>
            </tr>
        );

        let eventStatusType = "Alla evenemang"
        if (filter.status.length !== 0) {
            if (filter.status[0] === "available") {
                eventStatusType = "Inte slutsålda"
            }
            else if (filter.status[0] === "soldOut") {
                eventStatusType = "Endast slutsålda"
            }
        }
        const eventStatusFilter = (
            <tr className="text-left">
                <th scope="row">Status</th>
                <td>{eventStatusType}</td>
            </tr>
        );

        let sortingType = "Standard"
        if (filter.sorting.length !== 0) {
            if (filter.sorting[0] === "price-rising") {
                sortingType = "Pris stigande"
            }
            else if (filter.sorting[0] === "price-falling") {
                sortingType = "Pris fallande"
            }
            else if (filter.sorting[0] === "random") {
                sortingType = "Objektens ordning slumpas varje gång snurran visas"
            }
        }
        const sortingFilter = (
            <tr className="text-left">
                <th scope="row">Sortering</th>
                <td>{sortingType}</td>
            </tr>
        );
        
        let lastModified;
        if (this.props.history) {
            if (this.props.history.length > 0) {
                const last = this.props.history[this.props.history.length - 1];
                lastModified = (
                    <tr className="text-left">
                        <th scope="row" className="pt-3">Senast ändrad</th>
                        <td className="last-edited-by pt-3">{last.date}<br/>{last.username}</td>
                    </tr>
                )
            }
        }

        let customerRules;
        let offerTypeRules;
        if (this.props.customer.hasOwnProperty("rules")) {
            if (this.props.customer.rules.hasOwnProperty("offerTypes")) {
                offerTypeRules = this.props.customer.rules.offerTypes.map((type) => {
                    return offerTypeTranslate[type];
                });
            }
            if ((this.props.customer.rules.hasOwnProperty("objectLimit") && this.props.customer.rules.objectLimit !== null) || (this.props.customer.rules.hasOwnProperty("offerTypes") && this.props.customer.rules.offerTypes.length > 0)) {
                customerRules = (
                    <tr className="text-left">
                        <th scope="row" className="pt-3">Kundregler</th>
                        <td className="pt-3">
                            {
                                this.props.customer.rules.hasOwnProperty("objectLimit") &&
                                this.props.customer.rules.objectLimit !== null &&
                                <>
                                    Max {this.props.customer.rules.objectLimit} objekt
                                </>
                            }
                            {
                                (this.props.customer.rules.hasOwnProperty("objectLimit") && this.props.customer.rules.hasOwnProperty("offerTypes")) &&
                                <br/>
                            }
                            {
                                this.props.customer.rules.hasOwnProperty("offerTypes") &&
                                this.props.customer.rules.offerTypes.length > 0 &&
                                <>
                                    {offerTypeRules.join(", ")}
                                </>
                            }
                        </td>
                    </tr>
                )
            }
        }

        const emptyFilter = {
            city: [],
            area: [],
            type: [],
            running: [],
            brand: [],
            model: [],
            status: [],
            fuel: [],
            affiliate: [],
            price: {
                from: null,
                to: null
            },
            year: {
                from: null,
                to: null
            },
            mileage: {
                from: null,
                to: null
            },
            numObjects: null,
            offerType: [],
            hiddenObjects: [],
            firstObjectId: null,
            textFilter: null,
            sorting: []
        }
        return (
            <div className="bg-light campaign-info border-bottom animated fadeIn fast" key={li.id}>
                <div className="px-2 pt-1">
                    <table className="table table-borderless table-sm" style={liStyle}>
                        <thead>
                            <tr>
                                <th scope="col" style={{width: "40%"}}></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-left">
                                <th scope="row">Startdatum</th>
                                <td>{startDate}</td>
                            </tr>
                            <tr className="text-left">
                                <th scope="row">Slutdatum</th>
                                <td className={`${sites.length > 0 ? "" : "pb-3"}`}>{endDate}</td>
                            </tr>
                            {   
                                sites.length > 0 &&
                                <tr className="text-left">
                                    <th scope="row">Sajt</th>
                                    <td className="pb-3">{sites}</td>
                                </tr>
                            }
                            {filter.city.length > 0 && cityFilter}
                            {filter.area.length > 0 && areaFilter}
                            {filter.type.length > 0 && typeFilter}
                            {filter.brand.length > 0 && brandFilter}
                            {filter.model.length > 0 && modelFilter}
                            {filter.fuel.length > 0 && fuelFilter}
                            {filter.affiliate.length > 0 && affiliateFilter}
                            {(filter.price.from !== null || filter.price.to !== null) && priceFilter}
                            {(filter.year.from !== null || filter.year.to !== null) && yearFilter}
                            {(filter.mileage.from !== null || filter.mileage.to !== null) && mileageFilter}
                            {filter.numObjects !== null && numObjectsFilter}
                            {filter.offerType.length > 0 && offerTypeFilter}
                            {(filter.dates.from !== null && filter.dates.to !== null) && datesFilter}
                            {this.props.customer.type === "broker" && runningFilter}
                            {this.props.customer.type === "auto" && statusFilter}
                            {this.props.customer.type === "event" && eventStatusFilter}
                            {sortingFilter}
                            {filter.hiddenObjects.length > 0 && hiddenObjFilter}
                            {lastModified}
                            {customerRules}
                        </tbody>
                    </table>
                </div>
                <div className="text-center pb-3">
                    <button 
                        className="btn btn-info copy-paste-btn mr-1"
                        onClick={this.props.copyFilter}
                        disabled={JSON.stringify(this.props.filter) === JSON.stringify(emptyFilter)}
                    >
                        <i className="fas fa-copy"></i> Kopiera filter
                    </button>
                    <button 
                        className="btn btn-info copy-paste-btn ml-1"
                        onClick={this.props.pasteFilter} 
                        disabled={JSON.stringify(this.props.copiedFilter) === JSON.stringify(emptyFilter)}
                    >
                        <i className="fas fa-paste"></i> Klistra in filter
                    </button>
                </div>
            </div>
        )
    }
}