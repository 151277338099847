import React from 'react';
import Login from '../components/Login';

export class LoginContainer extends React.Component {


    render(){
        return <Login buttonClick={this.props.onClick} errorMessage={this.props.errorMessage} />
    }
}

