import React from 'react';

export class ObjectListNews extends React.Component {
    render(){
        const object = this.props.object;
        const starred = this.props.starred;
        const hidden = this.props.hidden;

        const newsLink = {
            textDecoration: 'none'
        }

        let content = object.details.content ? <span className="description-text">{object.details.content}</span> : "";
        let category = object.details.category ? <span className="description-text text-muted">{object.details.category}</span> : "";
        let date = object.details.date ? <span className="description-text text-muted">{object.details.date}</span> : "";
        
        return (
            <tr className={`animated fadeIn ${hidden ? "hide-object" : ""}`} key={object.uniqueId} style={{height: "81px"}}>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "300px"}}>
                    <a style={newsLink} className="w-100" href={object.details.link} target="_blank" rel="noopener noreferrer">
                        <strong>{object.details.title}</strong>
                    </a>
                </td>
                <td className={`pl-1 pb-1 pt-1 pr-3 ${hidden ? "hide-object" : ""}`} style={{width: "600px"}}>
                    {content}
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "250px"}}>
                    {category} {category && <br/>}
                    {date}
                </td>
                <td className={`p-1 ${hidden ? "hide-object" : ""}`} style={{width: "145px"}}>
                    <button className={`btn btn-link text-primary p-0 star-btn star-object-btn ${starred ? "obj-starred" : ""}`} onClick={this.props.updateFirstObject} data-uniqueid={object.uniqueId}
                    data-container="body" data-popover="popover" data-trigger="hover" data-placement="left" data-content={starred ? `Återställ objektets placering` : `Placera objektet först`}>
                        {starred ? <i className="fas fa-star text-warning"></i> : <i className="far fa-star"></i>}
                    </button>
                    <br/>
                    <button className="btn btn-link hide-object-btn text-primary p-0" onClick={this.props.updateHiddenObjects} data-uniqueid={object.uniqueId}
                    data-container="body" data-popover="popover" data-trigger="hover" data-placement="left" data-content={hidden ? `Visa objektet` : `Dölj objektet`}>
                        {hidden ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                    </button>
                </td>
            </tr>
        )
    }
}